import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for publishing plan
export const publishPlan = createAsyncThunk('plan/publishPlan', async (plan) => {
      	const response = await fetch(`/api/plans/publish-plan`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(plan)
		});
		if (!response.ok) {
			const errorText = await response.text();
			console.error('Network response was not ok:', errorText);
			throw new Error('Network response was not ok');
		}
  });
  
const publishPlanSlice = createSlice({
	name: 'plan',
	initialState: { status: 'idle', error: null },
	reducers: {},
	extraReducers: (builder) => {
		builder
		.addCase(publishPlan.pending, (state) => {
			state.status = 'loading';
		})
		.addCase(publishPlan.fulfilled, (state, action) => {
			state.status = 'succeeded';
		})
		.addCase(publishPlan.rejected, (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		});
	},
});

export default publishPlanSlice.reducer;