import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { fetchVoucherRedemptionsByUserId } from '../../redux/slices/voucherRedemptionsByUserIdSlice';
import { approveVoucherRedemption } from '../../redux/slices/approveVoucherRedemptionSlice';
import { rejectVoucherRedemption } from '../../redux/slices/rejectVoucherRedemptionSlice';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';

const socket = io(process.env.REACT_APP_SERVER_URL);

function ApproveVoucherRedemption() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const voucherRedemptions = useSelector((state) => state.voucherRedemptionsByUserId.voucherRedemptions);
    const [status, setStatus] = useState('');

    useEffect(() => {
        dispatch(fetchVoucherRedemptionsByUserId());

        // Listen for voucher status updates
        socket.on('voucherStatusUpdated', () => {
            dispatch(fetchVoucherRedemptionsByUserId());
        });

        return () => {
            socket.off('voucherStatusUpdated');
        };
    }, [dispatch]);

    const handleApproveRedemption = async (voucherRedemptionId) => {
        try {
            await dispatch(approveVoucherRedemption(voucherRedemptionId));
            // Emit an event to notify the server
            socket.emit('approveVoucherRedemption', { voucherRedemptionId, status: 'approved' });
            await dispatch(fetchVoucherRedemptionsByUserId());
            setStatus(t('vouchers.approved'));
            setTimeout(() => {
                setStatus('');
            }, 5000); // Show approved message for 5 seconds   
        } catch (error) {
            console.error(error);
        }
    }

    const handleRejectRedemption = async (voucherRedemptionId) => {
        try {
            await dispatch(rejectVoucherRedemption(voucherRedemptionId));
            socket.emit('approveVoucherRedemption', { voucherRedemptionId, status: 'rejected' });
            await dispatch(fetchVoucherRedemptionsByUserId());
            setStatus(t('vouchers.rejected'));
            setTimeout(() => {
                setStatus('');
            }, 5000); // Show approved message for 5 seconds   
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="container mt-5">
            <h2 className="mt-5 mb-3">{t('vouchers.approveRedemption')}</h2>
            <hr className="mb-5"/>
            {voucherRedemptions && voucherRedemptions.length > 0 && voucherRedemptions[0].status === null &&
                <>
                    <p>{t('vouchers.purchasePrice')}: €{voucherRedemptions[0].purchase_value}</p>
                    <p>{Math.round(voucherRedemptions[0].discount)}% {t('vouchers.off')}: <span className="fs-4 fw-bold">€{voucherRedemptions[0].final_price}</span></p>
                    <button className="btn btn-primary me-2" type="submit" onClick={() => handleApproveRedemption(voucherRedemptions[0].voucher_redemption_id)}>{t('buttons.approve')}</button>
                    <button className="btn btn-outline-secondary" type="submit" onClick={() => handleRejectRedemption(voucherRedemptions[0].voucher_redemption_id)}>{t('buttons.reject')}</button>
                </>
            }
            <p>{status}</p>
        </div>
    );
}

export default ApproveVoucherRedemption;