import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for genearte QR code
export const generateQrCode = createAsyncThunk('qrCode/generateQrCode', async () => {
  const response = await fetch(`/api/qrcodes/`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error);
  }

  return data
});

const generateQrCodeSlice = createSlice({
  name: 'qrcode',
  initialState: { qrcode: '', status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateQrCode.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(generateQrCode.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.qrcode = action.payload;
      })
      .addCase(generateQrCode.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default generateQrCodeSlice.reducer;