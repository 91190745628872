import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching ItemsForSale
export const fetchProjectsBySponsorId = createAsyncThunk('projects/fetchProjectsBySponsorId', async (sponsorId) => {
  const response = await fetch(`/api/projects/sponsor/${sponsorId}`);
  const data = await response.json();
  return data;
});

const projectsBySponsorIdSlice = createSlice({
  name: 'projects',
  initialState: { projects: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsBySponsorId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProjectsBySponsorId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.projects = action.payload;
      })
      .addCase(fetchProjectsBySponsorId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default projectsBySponsorIdSlice.reducer;