import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { scanQrCode } from '../../redux/slices/scanQrCodeSlice';
import QrScanner from 'react-qr-scanner';

const QrCodeScanner = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState('');

    const handleScan = (data) => {
        if (data) {
            try {
                dispatch(scanQrCode(data));
                alert('QR Code scanned successfully');
            } catch (error) {
                console.error('Error scanning QR code:', error);
                setError('Error scanning QR code');
            }
        }
    };

    const handleError = (err) => {
        console.error(err);
        setError('Error accessing camera');
    };

    const previewStyle = {
        height: 240,
        width: 320,
    };

    const videoConstraints = {
        facingMode: 'environment', // This specifies to use the back camera
    };

    return (
        <div>
            <h1>QR Code Scanner</h1>
            <QrScanner
                delay={300}
                style={previewStyle}
                constraints={{ video: videoConstraints }}
                onError={handleError}
                onScan={handleScan}
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default QrCodeScanner;
