import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching QR codes
export const fetchQrCodes = createAsyncThunk('qrCodes/fetchQrCodes', async () => {
  const response = await fetch('/api/qrcodes');
  const data = await response.json();
  return data;
});

const qrCodesSlice = createSlice({
  name: 'qrCodes',
  initialState: { qrCodes: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQrCodes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchQrCodes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.qrCodes = action.payload;
      })
      .addCase(fetchQrCodes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default qrCodesSlice.reducer;