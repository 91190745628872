import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching ItemsForSale
export const fetchSponsorsByProjectId = createAsyncThunk('sponsors/fetchSponsorsByProjectId', async (projectId) => {
  const response = await fetch(`/api/sponsors/project/${projectId}`);
  const data = await response.json();
  return data;
});

const sponsorsByProjectIdSlice = createSlice({
  name: 'sponsors',
  initialState: { sponsors: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSponsorsByProjectId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSponsorsByProjectId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sponsors = action.payload;
      })
      .addCase(fetchSponsorsByProjectId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default sponsorsByProjectIdSlice.reducer;