import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 

const OnboardingComplete = () => {
	const { t } = useTranslation();
	return (
		<div className="container-fluid">
        	<div className="row mt-5 pt-5 text-center">
				<h2>{t('onboarding.onboardingComplete')}</h2>
				<p>
					{t('onboarding.fullySetupMessage')}
				</p>			
				{/* Links to navigate to other parts of your application */}
				<div>
					<Link to="/grantee-dashboard" className="btn btn-primary">{t('buttons.goToDashboard')}</Link>
				</div>
			</div>
		</div>
  );
};

export default OnboardingComplete;

