import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching vouchers
export const fetchVouchers = createAsyncThunk('vouchers/fetchVouchers', async () => {
  const response = await fetch('/api/vouchers');
  const data = await response.json();
  return data;
});

const vouchersSlice = createSlice({
  name: 'vouchers',
  initialState: { vouchers: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVouchers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVouchers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vouchers = action.payload;
      })
      .addCase(fetchVouchers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default vouchersSlice.reducer;