import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for updating orders
export const updateCancelAtStripeSubscription = createAsyncThunk(
    'stripeSubscription/updateCancelAtStripeSubscription', 
    async ({ orderId, stripeSubscriptionId }) => {
      const response = await fetch('/api/orders/update-cancel-at', {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ orderId, stripeSubscriptionId })
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Network response was not ok:', errorText);
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
  });
  

const updateCancelAtStripeSubscriptionSlice = createSlice({
  name: 'stripeSubscription',
  initialState: { stripeSubscription: '', status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCancelAtStripeSubscription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCancelAtStripeSubscription.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stripeSubscription = action.payload;
      })
      .addCase(updateCancelAtStripeSubscription.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default updateCancelAtStripeSubscriptionSlice.reducer;