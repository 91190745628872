import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for creating plan
export const createPlan = createAsyncThunk('plan/createPlan', async ({ formData, projectId }) => {

  let response;
  try {
    response = await fetch(`/api/plans/project/${projectId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Plan creation failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in createPlan thunk', error);
    throw new Error(error.message || 'An error occurred during the API call');
  }
});

const createPlanSlice = createSlice({
  name: 'plan',
  initialState: { status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPlan.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.plan = action.payload;
      })
      .addCase(createPlan.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default createPlanSlice.reducer;
