import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import LanguageSwitcher from '../languages/LanguageSwitcher';

import './Navbar.css';

function Navbar() {
    const { t } = useTranslation();
    const isLoggedIn = useSelector((state) => state.authentication.isLoggedIn);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const sponsorUser = userData?.user.user_type === 'sponsor';
    const granteeUser = userData?.user.user_type === 'grantee';

    return (
        <nav className="navbar navbar-expand-lg bg-body fixed-top">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img src="https://storage.googleapis.com/sponsify/sponsify-logo/Sponsify.png" alt="Sponsify Logo" height="30px" />
                </Link>
                <button 
                    className="navbar-toggler" 
                    type="button" 
                    data-bs-toggle="offcanvas" 
                    data-bs-target="#offcanvasNavbar" 
                    aria-controls="offcanvasNavbar" 
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div 
                    className="offcanvas offcanvas-end" 
                    tabIndex="-1" 
                    id="offcanvasNavbar" 
                    aria-labelledby="offcanvasNavbarLabel"
                >
                    <div className="offcanvas-header">
                        <Link className="navbar-brand" to="/">
                            <img src="https://storage.googleapis.com/sponsify/sponsify-logo/Sponsify.png" alt="Sponsify Logo" height="30px" />
                        </Link>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li className="nav-item" data-bs-dismiss="offcanvas">
                                <Link className="nav-link active" aria-current="page" to="/">{t('navbar.home')}</Link>
                            </li>
                            <li className="nav-item" data-bs-dismiss="offcanvas">
                                <Link className="nav-link" to="/vouchers">{t('navbar.vouchers')}</Link>
                            </li>
                            <li className="nav-item" data-bs-dismiss="offcanvas">
                                <Link className="nav-link" to="/projects">{t('navbar.projects')}</Link>
                            </li>
                            <li className="nav-item" data-bs-dismiss="offcanvas">
                                <Link className="nav-link" to="/sponsors">{t('navbar.sponsors')}</Link>
                            </li>
                            {isLoggedIn && sponsorUser && 
                                <li className="nav-item d-block d-lg-none" data-bs-dismiss="offcanvas">
                                    <Link className="btn btn-sm btn-primary" to="/vouchers/approve-redemption" type="button">{t('navbar.approve')}</Link>
                                </li>
                            }
                            {isLoggedIn ? (
                                <li className="nav-item dropdown">
                                    <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        {userData.user.username}
                                    </button>
                                    <ul className="dropdown-menu">
                                        {granteeUser &&
                                            <>
                                            <li className="nav-item" data-bs-dismiss="offcanvas">
                                                <Link to={`update-grantee/${userData.user.id}`} className="nav-link" aria-current="page">{t('navbar.updateProfile')}</Link>
                                            </li>
                                            <li className="nav-item" data-bs-dismiss="offcanvas">
                                                <Link to="grantee-dashboard" className="nav-link" aria-current="page">{t('navbar.dashboard')}</Link>
                                            </li>
                                            <li><hr className="custom-divider" /></li>
                                            </>
                                        }
                                        {sponsorUser &&
                                            <>
                                            <li className="nav-item" data-bs-dismiss="offcanvas">
                                                <Link to="sponsor-dashboard" className="nav-link" aria-current="page">{t('navbar.dashboard')}</Link>
                                            </li>
                                            <li><hr className="custom-divider" /></li>
                                            </>
                                        }
                                        <li className="nav-item" data-bs-dismiss="offcanvas">
                                            <Link to="/logout" className="nav-link" aria-current="page">{t('navbar.logout')}</Link>
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                <>
                                    <li className="nav-item" data-bs-dismiss="offcanvas">
                                    <Link className="nav-link" to="/pre-grantee-registration">{t('navbar.getSponsored')}</Link>
                                    </li>
                                    <li className="nav-item" data-bs-dismiss="offcanvas">
                                        <Link className="nav-link" to="/sponsor-registration">{t('navbar.becomeSponsor')}</Link>
                                    </li>
                                    <li className="nav-item" data-bs-dismiss="offcanvas">
                                        <Link className="nav-link" to="/login">{t('navbar.login')}</Link>
                                    </li>
                                </>
                            )}
                        </ul>
                        {/* <LanguageSwitcher /> */}
                    </div>
                </div>
            </div>
        </nav>
        
    );
}


export default Navbar;
