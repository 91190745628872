import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for archiving plan
export const archivePlan = createAsyncThunk('plan/archivePlan', async (planId) => {
      	const response = await fetch(`/api/plans/${planId}/archive`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			const errorText = await response.text();
			console.error('Network response was not ok:', errorText);
			throw new Error('Network response was not ok');
		}
  });
  
const archivePlanSlice = createSlice({
	name: 'plan',
	initialState: { status: 'idle', error: null },
	reducers: {},
	extraReducers: (builder) => {
		builder
		.addCase(archivePlan.pending, (state) => {
			state.status = 'loading';
		})
		.addCase(archivePlan.fulfilled, (state, action) => {
			state.status = 'succeeded';
		})
		.addCase(archivePlan.rejected, (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		});
	},
});

export default archivePlanSlice.reducer;