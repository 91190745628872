import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching ItemsForSale
export const fetchPlans = createAsyncThunk('plans/fetchPlans', async () => {
  const response = await fetch('/api/plans');
  const data = await response.json();
  return data;
});

const plansSlice = createSlice({
  name: 'plans',
  initialState: { plans: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.plans = action.payload;
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default plansSlice.reducer;