import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 
import { fetchSponsors } from '../../redux/slices/sponsorsSlice';
import { useTranslation } from 'react-i18next';

function Sponsors() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSponsors());
    }, [dispatch]);

    const sponsors = useSelector((state) => state.sponsors.sponsors) || [];

    const activeSponsors = Array.isArray(sponsors) 
        ? sponsors.filter(sponsor => sponsor.orders.some(order => !order.subscription_cancel_at || new Date(order.subscription_cancel_at) > new Date()))
        : [];
    
    return (
        <div className="container mt-5">
            <h2 className="mt-5 mb-3">{t('sponsors.sponsors')}</h2>
            <hr className="mb-5"/>
            {activeSponsors.length > 0 ? (
                <div className="row">
                    {sponsors.map((sponsor) => (
                        <div key={sponsor.sponsor_id} className="col-lg-2">
                            <Link to={`/sponsors/${sponsor.sponsor_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="text-center">
                                    <img 
                                        src={`https://storage.googleapis.com/sponsify/sponsors-logo/${sponsor.sponsor_logo}`} 
                                        className="img-thumbnail" 
                                        alt={sponsor.sponsor_name}
                                        onError={(e) => {
                                            e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                        }}
                                    />
                                    <h4>{sponsor.sponsor_name}</h4>
                                </div> 
                            </Link>
                        </div>
                    ))}
                </div>
            ) : (
                <p>{t('sponsors.noActiveSponsorsFound')}</p>
            )}
        </div>
    );
}

export default Sponsors;