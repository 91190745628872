import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../redux/slices/resetPasswordSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PasswordReset = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const token = query.get('token');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const { status, error: resetError } = useSelector((state) => state.resetPassword);

  useEffect(() => {
    if (status === 'succeeded') {
        navigate('/password-reset-success');
    }
  }, [status, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await dispatch(resetPassword({ token, newPassword }));
    } catch (error) {
      setError(resetError || 'Failed to reset password');
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mt-5 pt-5 justify-content-center">
        <div className="col-lg-6">
          <h2 className="text-center">{t('login.resetPassword')}</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label" htmlFor="newPassword">{t('login.newPassword')}</label>
              <input
                className="form-control"
                type="password"
                id="newPassword"
                name="newPassword"
                autoComplete="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="confirmPassword">{t('registration.confirmPassword')}</label>
              <input
                className="form-control"
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {error && <div className="text-danger">{error}</div>}
            <button type="submit" className="btn btn-primary w-100" disabled={status === 'loading'}>
              {status === 'loading' ? 'Resetting...' : t('login.resetPassword')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
