export const loadGoogleMapsScript = (apiKey, callback) => {
	// Check if the script is already present in the DOM
	if (!document.querySelector(`script[src*="maps.googleapis.com/maps/api/js"]`)) {
		const script = document.createElement("script");
		script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
		script.async = true;
		script.onload = callback;
		document.body.appendChild(script);
	} else {
		// If script is already loaded, call the callback immediately
		if (typeof window.google !== 'undefined' && window.google.maps) {
			callback();
		}
	}
};
  