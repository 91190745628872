import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for updating plan
export const updatePlan = createAsyncThunk('plan/updatePlan', async ({ formData, planId }) => {
      	const response = await fetch(`/api/plans/${planId}/update`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(formData)
		});
		const data = await response.json();
		if (!response.ok) {
			const errorMessage = data?.message || 'Failed to update plan';
			throw new Error(errorMessage);
		}
  });
  

const updatePlanSlice = createSlice({
	name: 'plan',
	initialState: { status: 'idle', error: null },
	reducers: {
		resetError(state) {
		  state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
		.addCase(updatePlan.pending, (state) => {
			state.status = 'loading';
		})
		.addCase(updatePlan.fulfilled, (state, action) => {
			state.status = 'succeeded';
			state.plan = action.payload;
		})
		.addCase(updatePlan.rejected, (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		});
	},
});

export default updatePlanSlice.reducer;