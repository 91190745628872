import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching voucher
export const fetchVoucher = createAsyncThunk('voucher/fetchVoucher', async (voucherId) => {
  const response = await fetch(`/api/vouchers/${voucherId}`);
  const data = await response.json();
  return data;
});

const voucherSlice = createSlice({
  name: 'voucher',
  initialState: { voucher: '', status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVoucher.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVoucher.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.voucher = action.payload;
      })
      .addCase(fetchVoucher.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default voucherSlice.reducer;