import React from 'react';
import { useTranslation } from 'react-i18next';

const PasswordResetRequestSuccess = () => {
    const { t } = useTranslation();

    return (
        <div className="container-fluid">
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <div className="text-center">
                        <h1>{t('login.passwordResetRequestSuccessful')}</h1>
                        <p>
                            {t('login.passwordResetRequestSuccessfulMessage')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetRequestSuccess;
