const getMonthYear = (timestamp, country) => {
    const date = new Date(timestamp);
    const formatter = new Intl.DateTimeFormat(country, { month: 'short' });
    const monthShort = formatter.format(date); // Get abbreviated month, e.g., "nov."
    const year = date.getFullYear(); // Get the year
    return [monthShort, year]; // Return as an array
};

module.exports =  {
    getMonthYear
};



