import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const BenefitFields = ({ initialBenefits = [], onChange }) => {
    const { t } = useTranslation();
    const [benefits, setBenefits] = useState(initialBenefits);

    useEffect(() => {
        setBenefits(initialBenefits);
    }, [initialBenefits]);

    const handleBenefitChange = (index, newValue) => {
        const updatedBenefits = benefits.map((benefit, i) => 
            i === index ? { ...benefit, description: newValue } : benefit
        );
        setBenefits(updatedBenefits);
        onChange(updatedBenefits); // Notify parent of changes
    };

    const handleAddBenefit = () => {
        setBenefits([...benefits, { description: '' }]); // Add new empty benefit
    };

    const handleRemoveBenefit = (index) => {
        const updatedBenefits = benefits.filter((_, i) => i !== index);
        setBenefits(updatedBenefits);
        onChange(updatedBenefits); // Notify parent of changes
    };

    return (
        <fieldset>
                <legend className="fs-6">{t('plans.benefits')}</legend>
                {benefits.map((benefit, index) => (
                    <div key={index} className="benefit-field">
                        <div className="row mb-3">
                            <div className="col-11 pe-1">
                                <input
                                    className="form-control"
                                    type="text"
                                    value={benefit.description}
                                    onChange={(e) => handleBenefitChange(index, e.target.value)}
                                />
                            </div>
                            <div className="col-1 ms-0 ps-0">
                                <button 
                                    type="button" 
                                    onClick={() => handleRemoveBenefit(index)}
                                    className="btn-close" 
                                    aria-label="Close"
                                >
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            <button type="button" className="btn btn-outline-primary mb-3" onClick={handleAddBenefit}>+ {t('plans.benefits')}</button>
        </fieldset>
    );
};

export default BenefitFields;
