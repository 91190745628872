import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for creating Stripe account link
export const createAccountLink = createAsyncThunk('accountLink/createAccountLink', async (connectedAccountId) => {
  const response = await fetch(`/api/grantees/create-account-link/${connectedAccountId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error);
  }
  return data
});

const createAccountLinkSlice = createSlice({
  name: 'accountLink',
  initialState: { status: 'idle', error: null },
  reducers: {
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAccountLink.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAccountLink.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.accountLink = action.payload;
      })
      .addCase(createAccountLink.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default createAccountLinkSlice.reducer;