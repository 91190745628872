import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for redeem voucher
export const redeemVoucher = createAsyncThunk('voucher/redeemVoucher', async ({ voucherId, formData }) => {
      const response = await fetch(`/api/vouchers/${voucherId}`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Network response was not ok:', errorText);
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
  });
  

const redeemVoucherSlice = createSlice({
  name: 'redeemVoucher',
  initialState: { voucher: '', status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(redeemVoucher.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(redeemVoucher.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.voucher = action.payload;
      })
      .addCase(redeemVoucher.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default redeemVoucherSlice.reducer;