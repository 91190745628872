import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching plan by id
export const fetchPlan = createAsyncThunk('plan/fetchPlan', async (planId) => {
  const response = await fetch(`/api/plans/${planId}`);
  const data = await response.json();
  return data;
});

const planSlice = createSlice({
  name: 'plan',
  initialState: { plan: null, status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlan.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlan.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.plan = action.payload;
      })
      .addCase(fetchPlan.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default planSlice.reducer;