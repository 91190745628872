import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for scan QR code
export const scanQrCode = createAsyncThunk('qrCode/scanQrCode', async (qrCodeData) => {
  console.log('qrCodeData', qrCodeData);
  const response = await fetch(`/api/qrcodes/scan`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(qrCodeData)
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error);
  }

  return data
});

const scanQrCodeSlice = createSlice({
  name: 'qrcode',
  initialState: { qrcode: '', status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(scanQrCode.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(scanQrCode.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.qrcode = action.payload;
      })
      .addCase(scanQrCode.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default scanQrCodeSlice.reducer;