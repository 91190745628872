const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString(undefined, options) + " " + date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
    return formattedDate;
};

module.exports =  {
    formatDate
};



