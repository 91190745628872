import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { fetchImagesByProjectId } from './imagesByProjectIdSlice';
import { fetchProject } from './projectSlice';

// Create an async thunk for removing event date
export const removeEventDate = createAsyncThunk('eventDate/removeEventDate', async ({ eventDateId, projectId }, { dispatch }) => {
    try {
      const response = await fetch(`/api/projects/event-date/${eventDateId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const data = await response.json();
      // Fetch projects after event date removal
      dispatch(fetchProject(projectId));
      return data; 
    } catch (error) {
      console.error('Error removing event date:', error);
      throw error; // Propagate the error
    }
  }
);

const removeEventDateSlice = createSlice({
  name: 'eventDate',
  initialState: { status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removeEventDate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeEventDate.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(removeEventDate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default removeEventDateSlice.reducer;