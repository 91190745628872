import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchImagesByProjectId } from './imagesByProjectIdSlice';

// Create an async thunk for removing images
export const removeImage = createAsyncThunk('image/removeImage', async ({ imageId, projectId }, { dispatch }) => {
    try {
      const response = await fetch(`/api/projects/image/${imageId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const data = await response.json();
      // Fetch images after image removal
      dispatch(fetchImagesByProjectId(projectId));
      return data; 
    } catch (error) {
      console.error('Error removing image:', error);
      throw error; // Propagate the error
    }
  }
);

const removeImageSlice = createSlice({
  name: 'image',
  initialState: { status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removeImage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeImage.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(removeImage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default removeImageSlice.reducer;