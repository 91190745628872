import React from 'react';
import { useTranslation } from 'react-i18next';

const RegistrationSuccessPage = () => {
    const { t } = useTranslation();

    return (
        <div className="container mt-5 pt-5">
            <div className="text-center">
                <h1>{t('registration.registrationSuccessful')}</h1>
                <p>{t('registration.thanksForRegistering')}</p>
                <p>{t('registration.emailSent')}</p>
                <p>{t('registration.checkSpam')} <strong>{t('registration.spam')}</strong> {t('registration.or')} <strong>{t('registration.junk')}</strong> {t('registration.folder')}.</p>
                <p>{t('registration.onceYourEmailVerified')}</p>
            </div>
        </div>
    );
};

export default RegistrationSuccessPage;
