import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { fetchProject } from '../../redux/slices/projectSlice';
import { fetchSports } from '../../redux/slices/sportsSlice';
import { fetchSportCategories } from '../../redux/slices/sportCategoriesSlice';
import { updateProject, resetStatus } from '../../redux/slices/updateProjectSlice';
import { fetchImagesByProjectId } from '../../redux/slices/imagesByProjectIdSlice';
import { removeImage } from '../../redux/slices/removeImageSlice';
import { removeEventDate } from '../../redux/slices/removeEventDateSlice';
import AddressForm from '../forms/AddressForm';

import { formatDate } from '../../utils/formatDate';

const UpdateProject = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { projectId } = useParams();

    const updateProjectStatus = useSelector((state) => state.updateProject.status);
    const updateProjectError = useSelector((state) => state.updateProject.error);
    const project = useSelector((state) => state.project.project);
    const sports = useSelector((state) => state.sports.sports);
    const sportCategories = useSelector((state) => state.sportCategories.sportCategories);
    const images = useSelector((state) => state.imagesByProjectId.images);

    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [postcode, setPostcode] = useState('');
    const [addressFound, setAddressFound] = useState('');
    const [selectedSportCategory, setSelectedSportCategory] = useState('');
    const [files, setFiles] = useState([]);
    const [eventDates, setEventDates] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        contactPhone: '',
        sportCategoryKey: '',
        sportId: '',
        isOfficial: false,
        isSeasonal: false,
        venue: ''
    });

    useEffect(() => {
        if (projectId) {
            dispatch(fetchProject(projectId));
            dispatch(fetchImagesByProjectId(projectId));
        }
        dispatch(fetchSports()); 
        dispatch(fetchSportCategories()); 
    }, [dispatch, projectId]);

    // Pre-fill the form with project data
    useEffect(() => {
        if (project) {
            setFormData({
                name: project.project_name || '',
                description: project.project_description || '',
                contactFirstName: project.contact_first_name || '',
                contactLastName: project.contact_last_name || '',
                contactEmail: project.contact_email || '',
                contactPhone: project.contact_phone || '',
                sportCategoryKey: project.sport_category_key || '',
                sportId: project.sport_id || '',
                isOfficial: !!project.is_official,
                isSeasonal: !!project.is_seasonal,
                venue: project.venue || ''
            });
        }
        
    }, [project]);

    useEffect(() => {
        if (project) {
            formData.isSeasonal === true ? setAddressLine1('') : setAddressLine1(project.address_line_1);
            formData.isSeasonal === true ? setAddressLine2('') : setAddressLine2(project.address_line_2 || '');
            formData.isSeasonal === true ? setAddressFound('') : setAddressFound(project.address_found);
            formData.isSeasonal === true ? setPostcode('') : setPostcode(project.postcode);
            formData.isSeasonal === true ? setCity('') : setCity(project.city);
            formData.isSeasonal === true ? setCountry('') : setCountry(project.country);
        }
    }, [project, formData]);

    const handleSportChange = (event) => {
        setFormData({
            ...formData,
            sportId: event.target.value  
        });
    };

    const handleSportCategoryChange = (event) => {
        setFormData({
            ...formData,
            sportCategoryKey: event.target.value  
        });
        setSelectedSportCategory(event.target.value);
    };

    const handleIsOfficialChange = (event) => {
        setFormData({
            ...formData,
            isOfficial: event.target.value === "true" 
        });
    };

    const handleIsSeasonalChange = (event) => {
        setFormData({
            ...formData,
            isSeasonal: event.target.value === "true"  
        });
    };

    // Update individual event date
    const handleEventDateChange = (index, field, value) => {
        const newEventDates = [...eventDates];
        newEventDates[index][field] = value;
        setEventDates(newEventDates);
    };

    const handleRemoveEventDateFromDatabase = (eventDateId) => {
        dispatch(removeEventDate({ eventDateId, projectId }));
    };

    // Add a new date pair
    const handleAddDate = () => {
        setEventDates([...eventDates, { startDate: '', endDate: '' }]);
    };

    // Remove a date pair
    const handleRemoveDate = (index) => {
        setEventDates(eventDates.filter((_, i) => i !== index));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        maxFiles: 5
    });

    const handleDropzoneRemoveFile = (event, fileName) => {
        event.stopPropagation(); // Prevent the click event from bubbling up to the dropzone
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    };

    const handleRemoveImage = (imageId) => {
        dispatch(removeImage({ imageId, projectId }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create FormData object 
        const formDataWithImages = new FormData();

        // Append form data fields
        Object.entries(formData).forEach(([key, value]) => {
            formDataWithImages.append(key, value);
        });

        // Append event dates as JSON string
        formDataWithImages.append('eventDates', JSON.stringify(eventDates));

        // Append files
        files.forEach((file, index) => {
            formDataWithImages.append(`images`, file); // append each file
        })

        // Append data from AddressForm component
        formDataWithImages.append('addressLine1', addressLine1);
        formDataWithImages.append('addressLine2', addressLine2);
        formDataWithImages.append('city', city);
        formDataWithImages.append('country', country);
        formDataWithImages.append('postcode', postcode);
        formDataWithImages.append('addressFound', addressFound);

        // Dispatch action to create project
        await dispatch(updateProject({ projectId, formDataWithImages }));
    };

    useEffect(() => {
        // Redirect only if the project creation succeeds
        if (updateProjectStatus === 'succeeded') {
            navigate(`/projects/${projectId}`);
            dispatch(resetStatus());
        }
    }, [updateProjectStatus, projectId, dispatch, navigate]);

    // Filter sports based on the selected category
    const filteredSports = selectedSportCategory
        ? sports.filter(sport => sport[selectedSportCategory])
        : sports;

    return (
        <div className="container-fluid">
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <h2 className="text-center mb-5">{t('projects.editProject')}</h2>
                    <form onSubmit={handleSubmit}>
                    <div className="row gx-3">
                            <div className="col-lg-6 mb-4">
                                <label className="form-label" htmlFor="contactFirstName">{t('sports.contactFirstName')}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="contactFirstName"
                                    name="contactFirstName"
                                    autoComplete="given-name"
                                    value={formData.contactFirstName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-lg-6 mb-4">
                                <label className="form-label" htmlFor="contactLastName">{t('sports.contactLastName')}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="contactLastName"
                                    name="contactLastName"
                                    autoComplete="family-name"
                                    value={formData.contactLastName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row gx-3">
                            <div className="col-lg-6 mb-4">
                                <label className="form-label" htmlFor="contactEmail">{t('registration.email')}</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    id="contactEmail"
                                    name="contactEmail"
                                    autoComplete="email"
                                    value={formData.contactEmail}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-lg-6 mb-4">
                                <label className="form-label" htmlFor="contactPhone">{t('registration.phone')}</label>
                                <input
                                    className="form-control"
                                    type="tel"
                                    id="contactPhone"
                                    name="contactPhone"
                                    autoComplete="tel"
                                    value={formData.contactPhone}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        {/* Category Sport Select for sports filtering only */}
                        <p className="mb-2">{t('sports.category')}</p>
                        <select 
                            className="form-select mb-4" 
                            aria-label="Category select"
                            value={formData.sportCategoryKey}
                            onChange={handleSportCategoryChange}
                        >
                            <option value="" disabled>{t('sports.selectCategory')}</option>
                            {sportCategories.map(category => (
                                <option key={category.key} value={category.key}>{category.name}</option>
                            ))}
                        </select>

                        {/* Sports Select */}
                        <p className="mb-2">{t('sports.sport')}</p>
                        <select 
                            className="form-select mb-4" 
                            aria-label="Sport select"
                            value={formData.sportId}
                            onChange={handleSportChange}
                            required
                        >
                            <option value="">{t('sports.selectSport')}</option>
                            {filteredSports.map(sport => (
                                <option key={sport.id} value={sport.id}>{sport.name}</option>
                            ))}
                        </select>

                        <div className="form-check mb-2">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="isOfficial" 
                                id="isOfficialTrue"
                                value="true"
                                checked={formData.isOfficial === true}
                                onChange={handleIsOfficialChange}
                            />
                            <label className="form-check-label" htmlFor="isOfficialTrue">
                                {t('sports.official')}
                            </label>
                        </div>
                        <div className="form-check mb-4">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="isOfficial" 
                                id="isOfficialFalse" 
                                value="false"
                                checked={formData.isOfficial === false}
                                onChange={handleIsOfficialChange}
                            />
                            <label className="form-check-label" htmlFor="isOfficialFalse">
                                {t('sports.nonOfficial')}
                            </label>
                        </div>

                        <div className="form-check mb-2">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="isSeasonal" 
                                id="isSeasonalTrue"
                                value="true"
                                checked={formData.isSeasonal === true}
                                onChange={handleIsSeasonalChange}
                            />
                            <label className="form-check-label" htmlFor="isSeasonalTrue">
                                {t('sports.seasonal')}
                            </label>
                        </div>
                        <div className="form-check mb-4">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                name="isSeasonal" 
                                id="isSeasonalFalse" 
                                value="false"
                                checked={formData.isSeasonal === false}
                                onChange={handleIsSeasonalChange}
                            />
                            <label className="form-check-label" htmlFor="isSeasonalFalse">
                                {t('sports.oneOff')}
                            </label>
                        </div>

                        <div className="mb-4">
                            <label className="form-label" htmlFor="name">{t('projects.name')}</label>
                            <input
                                className="form-control"
                                type="text"
                                id="name"
                                name="name"
                                autoComplete="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="form-label" htmlFor="description">{t('projects.description')}</label>
                            <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                autoComplete="description"
                                value={formData.description}
                                onChange={handleChange}
                                required
                                rows="4"
                            />
                        </div>

                        {/* date and time picker */}
                        <p className="pb-0 mb-2">{t('sports.eventDate')}</p> 
                        <ul className="list-group mb-3">
                            {project?.event_dates?.map((date, index) => (
                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                    {`${formatDate(date.start_date)} ${t('general.toDate')} ${formatDate(date.end_date)}`}
                                    <span>
                                        <button 
                                            onClick={() => handleRemoveEventDateFromDatabase(date.event_date_id)}
                                            type="button" 
                                            className="btn-close" 
                                            aria-label="Close"
                                        ></button>
                                    </span>
                                </li>
                            ))}
                        </ul>
                        <ul className="list-group mb-3">
                            {eventDates.map((datePair, index) => (
                                index > 0 && (
                                    <li key={index} className="list-group-item">
                                        <div className="row align-items-center">
                                            <div className="col-lg-5">
                                                <input 
                                                    type={formData.isSeasonal === false ? "datetime-local" : "date"} 
                                                    name="startDate" 
                                                    className="form-control me-3"
                                                    value={datePair.startDate}
                                                    onChange={(e) => handleEventDateChange(index, 'startDate', e.target.value)}
                                                    min={formData.isSeasonal === false 
                                                        ? new Date().toISOString().slice(0, 16) 
                                                        : new Date().toISOString().slice(0, 10)}
                                                />
                                            </div>
                                            <div className="col-lg-1 text-center">{t('general.toDate')}</div>
                                            <div className="col-lg-5">
                                                <input 
                                                    type={formData.isSeasonal === false ? "datetime-local" : "date"}
                                                    name="endDate" 
                                                    className="form-control me-3"
                                                    value={datePair.endDate}
                                                    onChange={(e) => handleEventDateChange(index, 'endDate', e.target.value)}
                                                    min={ formData.isSeasonal === false 
                                                        ? datePair.startDate || new Date().toISOString().slice(0, 16) 
                                                        : datePair.startDate || new Date().toISOString().slice(0, 10)}
                                                />
                                            </div>
                                            <div className="col-lg-1 text-end">
                                                <button 
                                                    type="button" 
                                                    onClick={() => handleRemoveDate(index)}
                                                    className="btn-close" 
                                                    aria-label="Close"
                                                >     
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                )
                            ))}
                        </ul>
                        <button 
                            type="button" 
                            className="btn btn-outline-secondary text-capitalize mb-4" 
                            onClick={handleAddDate}>+ {t('general.date')}
                        </button>

                        {formData.isSeasonal === false && (
                            <div className="mb-4">
                                <label className="form-label" htmlFor="venue">{t('sports.venue')}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="venue"
                                    name="venue"
                                    autoComplete="venue"
                                    placeholder="Ejemplo: Polideportivo San Ignazio"
                                    value={formData.venue}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        )}

                        {formData.isSeasonal === false && (
                            <AddressForm
                                addressLine1={addressLine1}
                                setAddressLine1={setAddressLine1}
                                addressLine2={addressLine2}
                                setAddressLine2={setAddressLine2}
                                city={city}
                                setCity={setCity}
                                country={country}
                                setCountry={setCountry}
                                postcode={postcode}
                                setPostcode={setPostcode}
                                addressFound={addressFound}
                                setAddressFound={setAddressFound}
                            />
                        )}

                        <div className="mb-3">
                            <div {...getRootProps({ className: 'dropzone' })} className="dropzone border-secondary-subtle rounded-2">
                                <input {...getInputProps()} />
                                <p className="small text-secondary">{t('projects.dropzone')}</p>
                                <div className="dropzone-file-list">
                                    {files?.map((file, index) => (
                                        <p key={index}>
                                            {file.name}
                                            <button 
                                                type="button" 
                                                className="dropzone-remove-file-button" 
                                                onClick={(e) => handleDropzoneRemoveFile(e, file.name)}
                                            >
                                                &times;
                                            </button>
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {images.map(image => (
                                <div className="col-lg-4 col-md-4 col-sm-6" key={image.id}>
                                    <img 
                                        src={`https://storage.googleapis.com/sponsify/projects/${image.image}`} 
                                        className="img-fluid mb-3 me-3" 
                                        width="150px" 
                                        height="auto" 
                                        alt={image.image} 
                                    />
                                    <span>
                                        <button 
                                            onClick={() => handleRemoveImage(image.id)}
                                            type="button" 
                                            className="btn-close" 
                                            aria-label="Close"
                                        ></button>
                                    </span>
                                </div>
                            ))}
                        </div>  
                        <div className="">
                            <button type="submit" className="btn btn-lg btn-primary w-100" disabled={updateProjectStatus === 'loading'}>
                                {updateProjectStatus === 'loading' ? 'Updating Project...' : t('buttons.submit')}
                            </button>
                            {updateProjectError && <div className="text-danger mt-3">{updateProjectError}</div>}
                        </div>
                    </form>   
                </div>
            </div>    
        </div>
    );
};

export default UpdateProject;