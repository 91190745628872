import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { fetchVoucherRedemptionsByVoucherId } from '../../redux/slices/voucherRedemptionsByVoucherIdSlice';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function SponsorDashboard() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { voucherId, voucherIdentifier } = useParams();

    const voucherRedemptions = useSelector((state) => state.voucherRedemptionsByVoucherId.voucherRedemptionsByVoucherId);

    useEffect(() => {
        dispatch(fetchVoucherRedemptionsByVoucherId(voucherId));
    }, [voucherId, dispatch]);

    return (
        <div className="container mt-5 pt-5">
            <h2 className="">{t('vouchers.voucherRedemptions')} <span className="fs-6">{voucherIdentifier}</span></h2>
            <hr className="mb-3"/>
            <table className="table mb-5">
                <thead className="text-capitalize">
                    <tr>
                        <th>{t('general.date')}</th>
                        <th>{t('general.price')}</th>
                        <th>{t('general.finalPrice')}</th>
                    </tr>
                </thead>
                <tbody>
                    {voucherRedemptions.length > 0 && voucherRedemptions?.map((redemption, index) => (
                        <tr key={index}>
                            <td>
                                {new Date(redemption.redeemed_at).toLocaleDateString('en-GB')} 
                                {' '}
                                {new Date(redemption.redeemed_at).toLocaleTimeString('en-GB')}
                            </td>
                            <td>€{redemption.purchase_value}</td>
                            <td>€{redemption.final_price}</td>        
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default SponsorDashboard;
  