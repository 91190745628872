import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 
import { useTranslation } from 'react-i18next';

import { fetchProjectsByUserId } from '../../redux/slices/projectsByUserIdSlice';
import { fetchPlansByGranteeId } from '../../redux/slices/plansByGranteeIdSlice';
import { fetchGranteeByUserId } from '../../redux/slices/granteeByUserIdSlice';
import { fetchSubscribedPlans } from '../../redux/slices/subscribedPlansSlice';
import { checkConnectedAccountStatus } from '../../redux/slices/checkConnectedAccountStatusSlice';
import { createAccountLink } from '../../redux/slices/createAccountLinkSlice';
import { archivePlan } from '../../redux/slices/archivePlanSlice';
import { removePlan } from '../../redux/slices/removePlanSlice';

import Modal from 'react-bootstrap/Modal';


function GranteeDashboard() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user?.id;

    const projects = useSelector((state) => state.projectsByUserId.projects);
    const plans = useSelector((state) => state.plansByGranteeId.plansByGranteeId);
    const subscribedPlans = useSelector((state) => state.subscribedPlans.subscribedPlans);
    const grantee = useSelector((state) => state.granteeByUserId.granteeByUserId);
    const connectedAccountStatus = useSelector((state) => state.checkConnectedAccountStatus.connectedAccountStatus);
    const accountLink = useSelector((state) => state.createAccountLink.accountLink);

    const [showRemoveArchivePlanConfirmation, setShowRemoveArchivePlanConfirmation] = useState(false);
    const [planIdToRemoveArchive, setPlanIdToRemoveArchive] = useState('');
    const [planToRemoveArchive, setPlanToRemoveArchive] = useState({});
    const [isPublishedPlan, setIsPublishedPlan] = useState(false);

    const connectedAccountId = grantee?.stripe_connected_account_id;

    useEffect(() => {
        if (userId) {
            dispatch(fetchProjectsByUserId(userId));
            dispatch(fetchGranteeByUserId(userId));
        }
        dispatch(fetchPlansByGranteeId());
        dispatch(fetchSubscribedPlans());
    }, [userId, dispatch]);

    useEffect(() => {
        if (connectedAccountId) {
            dispatch(checkConnectedAccountStatus(connectedAccountId));
            dispatch(createAccountLink(connectedAccountId));
        }
    }, [connectedAccountId, dispatch]);

    // Check if the plans are subscribed
    let plansWithSubscriptionStatus
    if (Array.isArray(plans)) {
        // Ensure subscribedPlans is an array before mapping
        const subscribedPlanIds = Array.isArray(subscribedPlans)
            ? subscribedPlans.map(plan => plan.plan_id)
            : [];
        plansWithSubscriptionStatus = plans?.map(plan => ({
            ...plan,
            is_subscribed: subscribedPlanIds.includes(plan.plan_id),
        }));
    };

    // Add is_cancelled to plansWithSubscriptionStatus
    // Check if all cancel_at are non-null for a specific plan_id
    const plansWithSubscriptionStatusAndWithIsCancelled = plansWithSubscriptionStatus?.map(plan => {
        // Ensure subscribedPlans is an array and get all subscribed plans for this plan_id
        const relatedSubscribedPlans = Array.isArray(subscribedPlans) 
            ? subscribedPlans?.filter(sub => sub.plan_id === plan.plan_id) 
            : [];
        // Check if all cancel_at values are non-null
        const isCancelled = relatedSubscribedPlans.length > 0 && relatedSubscribedPlans.every(sub => sub.cancel_at && new Date(sub.cancel_at) < new Date());
        // Add is_cancelled to the plan
        return {
            ...plan,
            is_cancelled: isCancelled
        };
    });

    const handleRemoveArchivePlan = async (planId) => {
        setPlanIdToRemoveArchive(planId); // no need to await
        const plan = plans.find(({ plan_id }) => plan_id === planId); 
        setIsPublishedPlan(plan.plan_published);
        setPlanToRemoveArchive(plan);
        setShowRemoveArchivePlanConfirmation(true);
    };

    const cancelRemoveArchivePlan = () => {
        setShowRemoveArchivePlanConfirmation(false);
    };

    const confirmRemoveArchivePlan = async () => {
        if (isPublishedPlan) {
            await dispatch(archivePlan(planIdToRemoveArchive));
        } else {
            await dispatch(removePlan(planIdToRemoveArchive));
        }
        
        await dispatch(fetchPlansByGranteeId());
        await setShowRemoveArchivePlanConfirmation(false);
    };

    return (
        <div className="container mt-5 pt-5">
            {connectedAccountStatus && accountLink && connectedAccountStatus.status === 'restricted' && 
                <div className="alert alert-danger mb-5">
                    <p>{t('onboarding.restrictedAccountMessage')}</p>
                    <Link to={accountLink.url} className="btn btn-sponsify-red me-3" aria-label="Complete bank details setup">
                        {t('buttons.completeSetup')}
                    </Link>
                </div>
            }
            <h3 className="">{t('projects.myProjects')} 
                <span className="ms-3">
                    <Link to={`/create-project`} className="btn btn-primary" type="button">{t('projects.createProject')}</Link>
                </span>
            </h3>
            <hr className="mb-3"/>
            <table className="table mb-5">
                <tbody>
                    {projects.length > 0 && projects?.map((project) => (
                        <tr key={project.project_id}>
                            <td>
                                <img 
                                    src={`https://storage.googleapis.com/sponsify/projects/${project.project_images[0]}`} 
                                    className="p-1 border"
                                    alt={project.project_name}
                                    height="50px"
                                    onError={(e) => {
                                        e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                    }} 
                                />
                            </td>
                            <td>{project.project_name}</td>
                            <td>
                                <Link to={`/update-project/${project.project_id}`} className="btn btn-primary" type="button">{t('buttons.edit')}</Link>
                            </td> 
                            {/* <td>
                                <Link to={`/create-plan/project/${project.project_id}`} className="btn btn-primary" type="button">{t('plans.createPlan')}</Link>
                            </td>  */}
                            <td>
                                <Link to={`/plan-templates/project/${project.project_id}`} className="btn btn-primary" type="button">{t('plans.addPlan')}</Link>
                            </td>                        
                        </tr>
                    ))}
                </tbody>
            </table>
            <h3 className="">{t('plans.myPlans')}</h3>
            <hr className="mb-3"/>
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('plans.plan')}</th>
                        <th>{t('projects.project')}</th>
                        <th>{t('plans.price')}</th>
                        <th>{t('plans.status')}</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {plansWithSubscriptionStatusAndWithIsCancelled?.map((plan, index) => (
                        <tr key={index}>
                            <td>{plan.plan_name}</td>
                            <td>{plan.project_name}</td>
                            <td>€{plan.plan_prices[0].plan_price.toFixed(2)}/{t('general.mo')}</td>
                            <td>
                                {
                                    plan.plan_archived && !plan.is_subscribed ? t('plans.archived')
                                    : plan.plan_archived && plan.is_subscribed && !plan.is_cancelled ? t('plans.archivedHasSubscription')
                                    : plan.is_cancelled ? t('plans.cancelled') 
                                    : plan.is_subscribed ? t('plans.subscribed') : t('plans.noSubscription')
                                }
                            </td>
                            <td>
                                <Link 
                                    to={`/update-plan/${plan.plan_id}`} 
                                    className={`${plan.is_subscribed || plan.plan_archived ? 'disabled' : '' } btn btn-primary`} 
                                    type="button"
                                >
                                    {t('buttons.edit')}
                                </Link>
                            </td> 
                            <td>
                                <button 
                                    onClick={() => handleRemoveArchivePlan(plan.plan_id)}
                                    className={`${plan.plan_archived ? 'disabled' : '' } btn btn-close`}
                                    aria-label="Close"
                                >
                                </button>
                            </td>                        
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal show={showRemoveArchivePlanConfirmation} >
                <Modal.Header closeButton onClick={cancelRemoveArchivePlan}>
                    <Modal.Title className="text-capitalize">  
                        {planToRemoveArchive && !isPublishedPlan ? (
                            t('plans.confirmPlanRemoval')
                        ) : (
                            t('plans.confirmPlanArchiving')
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {planToRemoveArchive && !isPublishedPlan ? (
                        <p>{t('plans.removePlanModalMessage')} <strong>{planToRemoveArchive.plan_name}</strong>?</p>
                    ) : (
                        <p>{t('plans.archivePlanModalMessage')} <strong>{planToRemoveArchive.plan_name}</strong>?</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={cancelRemoveArchivePlan}>{t('buttons.cancel')}</button>
                    <button type="button" className="btn btn-primary" onClick={confirmRemoveArchivePlan}>{t('buttons.confirm')}</button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default GranteeDashboard;
  