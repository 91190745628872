import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching image by project id
export const fetchImagesByProjectId = createAsyncThunk('images/fetchImagesByProjectId', async (projectId) => {
  const response = await fetch(`/api/projects/${projectId}/images`);
  const data = await response.json();
  return data;
});

const imagesByProjectIdSlice = createSlice({
  name: 'images',
  initialState: { images: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchImagesByProjectId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchImagesByProjectId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.images = action.payload;
      })
      .addCase(fetchImagesByProjectId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default imagesByProjectIdSlice.reducer;