// CookieBanner.js
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const CookieBanner = () => {
    const { t } = useTranslation();
    const [isBannerVisible, setBannerVisible] = useState(false);

    useEffect(() => {
        // Check if the user has already made a choice about cookies
        const cookieConsent = Cookies.get('cookieConsent');
        if (!cookieConsent) {
        setBannerVisible(true);
        }
    }, []);

    const acceptAllCookies = () => {
        // Set consent for all cookies
        Cookies.set('cookieConsent', 'all', { expires: 365 });
        // Set necessary and non-essential cookies here if needed
        // e.g., setAnalyticsCookies();
        setBannerVisible(false);
    };

    const rejectNonEssentialCookies = () => {
        // Set consent only for essential cookies
        Cookies.set('cookieConsent', 'necessary', { expires: 365 });
        // Make sure to remove any non-essential cookies
        // e.g., removeAnalyticsCookies();
        setBannerVisible(false);
    };

    if (!isBannerVisible) return null;

    return (
        <div className="fixed-bottom bg-white py-3 border border-2">
        <div className="container text-center">
            <p>
                {t('cookieBanner.message')}
            </p>
            <div className="d-flex justify-content-center mt-2">
            <button
                onClick={acceptAllCookies}
                className="btn btn-primary mx-2"
            >
                {t('cookieBanner.acceptAllCookies')}
            </button>
            <button
                onClick={rejectNonEssentialCookies}
                className="btn btn-secondary mx-2"
            >
                {t('cookieBanner.rejectNonEssentialCookies')}
            </button>
            </div>
        </div>
        </div>
    );
};

export default CookieBanner;
