import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams} from 'react-router-dom'; 
import { createAccountLink } from '../../redux/slices/createAccountLinkSlice';
import { useTranslation } from 'react-i18next';

const OnboardingRefresh = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { connectedAccountId } = useParams();

	const accountLink = useSelector((state) => state.createAccountLink.accountLink);

	useEffect(() => {
         dispatch(createAccountLink(connectedAccountId));
    }, [dispatch, connectedAccountId]);


	return (
		<div className="container-fluid">
        	<div className="row mt-5 pt-5 text-center">
				<h2>{t('onboarding.linkIssueMessage')}</h2>
				<p>
					{t('onboarding.completeSetupMessage')}
				</p>
				{accountLink ? (
					<div>
						<Link to={accountLink.url} className="btn btn-primary" aria-label="Complete bank details setup">
						{t('buttons.provideBankDetails')}
						</Link>
					</div>
				) : (
					<p>{t('onboarding.generatingAccountLink')}</p>
				)}
			</div>
		</div>
  );
};

export default OnboardingRefresh;

