import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching grantee by user id
export const fetchGranteeByUserId = createAsyncThunk('granteeByUserId/fetchGranteeByUserId', async (userId) => {
  const response = await fetch(`/api/grantees/user/${userId}`);
  const data = await response.json();
  return data;
});

const granteeByUserIdSlice = createSlice({
  name: 'granteeByUserId',
  initialState: { granteeByUserId: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGranteeByUserId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGranteeByUserId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.granteeByUserId = action.payload;
      })
      .addCase(fetchGranteeByUserId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default granteeByUserIdSlice.reducer;