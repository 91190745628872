import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchPlanTemplates } from '../../redux/slices/planTemplatesSlice';
import { copyPlanTemplate } from '../../redux/slices/copyPlanTemplateSlice';
import { useTranslation } from 'react-i18next';

function PlanTemplates() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { projectId } = useParams();

    const planTemplates = useSelector((state) => state.planTemplates.planTemplates);

    useEffect(() => {
        dispatch(fetchPlanTemplates());
    }, [dispatch]);

    const handleAddPlan = (planTemplateId, planTemplateName, planTemplatePrice) => {
        dispatch(copyPlanTemplate({ projectId, planTemplateId, planTemplateName, planTemplatePrice }));
        setTimeout(() => {
            navigate(`/projects/${projectId}`);
        }, 1000); 
    };

    return (
        <div className="container mt-5">
            <h2 className="mt-5 mb-3">{t('plans.planTemplates')}</h2>
            <hr className="mb-5"/>
            <div className="row">
                {Array.isArray(planTemplates) &&
                planTemplates?.map((plan) => (
                    <div className="col-lg-4 mb-4" key={plan.plan_id}>
                        <div className="card">
                            <div className="card-body d-flex flex-column justify-content-center">
                                <h4 className="card-title text-center">{plan.plan_name}</h4>
                                <h2 className="card-title text-center pricing-card-title">
                                    {plan.plan_price === 0.00 ? 'Free' : `€${plan.plan_prices[0].plan_price.toFixed(2)}`} 
                                    <small className="fs-6">/{t('general.mo')}</small>
                                </h2>
                                <ul className="list-group list-group-flush mt-3 mb-4">
                                    {plan.plan_benefits.map((benefit) => (
                                        <li className="list-group-item" key={benefit.benefit_id}>{benefit.description}</li>
                                    ))}
                                </ul>
                                <button 
                                    onClick={() => handleAddPlan(plan.plan_id, plan.plan_name, plan.plan_prices[0].plan_price)} 
                                    type="button" 
                                    className="btn btn-lg btn-block btn-primary"
                                >
                                    {t('plans.addPlan')}
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
     
    );
};

export default PlanTemplates;