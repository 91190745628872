import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching plans by grantee id
export const fetchPlansByGranteeId = createAsyncThunk('plansByGranteeId/fetchPlansByGranteeId', async () => {
  const response = await fetch(`/api/plans`);
  const data = await response.json();
  return data;
});

const plansByGranteeIdSlice = createSlice({
  name: 'plansByGranteeId',
  initialState: { plansByGranteeId: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlansByGranteeId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlansByGranteeId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.plansByGranteeId = action.payload;
      })
      .addCase(fetchPlansByGranteeId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default plansByGranteeIdSlice.reducer;