import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching sports
export const fetchSports = createAsyncThunk('sports/fetchSports', async () => {
  const response = await fetch('/api/sports');
  const data = await response.json();
  return data;
});

const sportsSlice = createSlice({
  name: 'sports',
  initialState: { sports: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSports.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSports.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sports = action.payload;
      })
      .addCase(fetchSports.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default sportsSlice.reducer;