import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './HomePage.css'

const HomePage = () => {
    return (
        <>
        <div className="bg-primary text-light px-4 py-5 mt-5 text-center">
            <div className="py-5">
                <h1 className="display-5 fw-bold text-white">¡Únete Al Movimiento Sponsify!</h1>
                <div className="col-lg-6 mx-auto">
                    <p className="fs-5 mb-4">
                        Como patrocinador podrás saber en todo momento el retorno de tu inversión. Sponsify ofrece el ROI del patrocinador, 
                        también conocido como ROSI (Return On Sponsorship Investment).<br/>
                        El patrocinio deportivo debe ser para todos, así que Sponsify se crea con este objetivo.<br/>
                        Facilitamos el proceso de patrocinio para ampliar el acceso a todos los niveles deportivos. Nos enfocamos en democratizar 
                        el acceso e incentivar a las pequeñas y medianas empresas a invertir en el deporte de forma profesional, independientemente del valor monetario de la inversión.
                    </p>
                    <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                        <a href="#sponsor" type="button" className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold">Quiero Patrocinar</a>
                        <a href="#grantee" type="button" className="btn btn-outline-light btn-lg px-4 fw-bold">Busco Patrocinio</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <section id="sponsor">
                <h2 className="mt-5">¿Quieres Patrocinar?</h2>
                <hr className="hr text-sponsify-red"/>
                <div className="row">
                    <div className="col-1">
                        <FontAwesomeIcon className="fs-1 text-sponsify-red" icon="fa-solid fa-filter" />
                    </div>
                    <div className="col-11">
                        <h5>Elije el proyecto que tenga más sentido para tu marca</h5> 
                        <p>
                            Analiza el perfil del público que asiste a los partidos o eventos. 
                            Elije el proyecto en el cual los asistentes son potenciales clientes para tu negocio.
                        </p>
                    </div>
                </div>
                <hr className="hr text-sponsify-red"/>
                <div className="row">
                    <div className="col-1">
                        <FontAwesomeIcon className="fs-1 text-sponsify-red" icon="fa-solid fa-hand-holding-dollar" />
                    </div>
                    <div className="col-11">
                        <h5>Invierte en el pack que encaja con tu presupuesto</h5> 
                        <p>
                            Cada proyecto incluye diferentes packs, y cada uno de ellos ofrece diversas oportunidades para promocionar tu empresa. 
                            Invierte en el que mejor se ajuste a tu presupuesto y que, según el perfil de tus clientes, te ofrezca más posibilidades 
                            de aumentar tus ventas, atraer nuevos clientes y fortalecer la imagen de tu marca.
                        </p>
                    </div>
                </div>
                <hr className="hr text-sponsify-red"/>
                <div className="row">
                    <div className="col-1">
                        <FontAwesomeIcon className="fs-1 text-sponsify-red" icon="fa-solid fa-tags" />
                    </div>
                    <div className="col-11">
                        <h5>Crea uno o más cupones de descuento</h5> 
                        <p>
                            Al convertirte en patrocinador, puedes crear diferentes cupones o vales para atraer más clientes a tu establecimiento o negocio. 
                            Todos los gastos que entran a través de Sponsify son computados y podrás saber a cualquier momento cual está siendo el retorno 
                            de tu inversión, para así tomar decisiones, como subir el valor o cambiar el tipo de inversión.
                        </p>
                    </div>
                </div>
                <hr className="hr text-sponsify-red"/>
                <div className="row">
                    <div className="col-1">
                        <FontAwesomeIcon className="fs-1 text-sponsify-red" icon="fa-solid fa-power-off" />
                    </div>
                    <div className="col-11">
                        <h5>Activa tu patrocinio</h5> 
                        <p>
                            Haz que todos sepan que apoyas el deporte. Además de proyectar una excelente imagen para tu marca, los familiares y amigos de los 
                            beneficiados por los proyectos patrocinados te lo agradecerán y preferirán tus productos o servicios por encima de la competencia. 
                            Algunos proyectos ya incluyen activaciones, pero también puedes agregar tus propias ideas para maximizar las oportunidades. 
                            Organiza encuentros con deportistas, eventos promocionales, crea tu mascota, etc. Si necesitas ideas, no dudes en ponerte en contacto con nosotros.
                        </p>
                    </div>
                </div>
            </section>
            <section id="grantee">
            <h2 className="mt-5">¿Buscas Patrocinio?</h2>
                <hr className="hr text-sponsify-red"/>
                <div className="row">
                    <div className="col-1">
                        <FontAwesomeIcon className="fs-1 text-sponsify-red" icon="fa-solid fa-right-to-bracket" />
                    </div>
                    <div className="col-11">
                        <h5>Regístrate</h5> 
                        <p>
                            Si eres un deportista o familiar, amigo de alguien que practica deporte minoritario, de base, conoces a deportistas independientes, 
                            equipos escolares o universitarios, o incluso si trabajas en un club deportivo y quieres colaborar para que consigan más inversión, 
                            ¡Regístrate en Sponsify.io!
                        </p>
                    </div>
                </div>
                <hr className="hr text-sponsify-red"/>
                <div className="row">
                    <div className="col-1">
                        <FontAwesomeIcon className="fs-1 text-sponsify-red" icon="fa-solid fa-diagram-project" />
                    </div>
                    <div className="col-11">
                        <h5>Crea tantos proyectos cuantos quieras</h5> 
                        <p>
                            Regístrate en “busco patrocinio” y luego podrás crear todos los proyectos que desees. Sube fotos y una descripción resumida de cada uno. 
                        </p>
                    </div>
                </div>
                <hr className="hr text-sponsify-red"/>
                <div className="row">
                    <div className="col-1">
                        <FontAwesomeIcon className="fs-1 text-sponsify-red" icon="fa-solid fa-table-columns" />
                    </div>
                    <div className="col-11">
                        <h5>Incluye los Planes de Patrocinio</h5> 
                        <p>
                            Puedes crear todos los planes que quieras, recomendamos que sean entre 3 y 5. 
                            Verás algunas sugerencias de planes (oro, plata y bronce) que podrás editarlos completamente de acuerdo con el perfil de tu proyecto.
                        </p>
                    </div>
                </div>
                <hr className="hr text-sponsify-red"/>
                <div className="row">
                    <div className="col-1">
                        <FontAwesomeIcon className="fs-1 text-sponsify-red" icon="fa-solid fa-power-off" />
                    </div>
                    <div className="col-11">
                        <h5>Activación de Patrocinio</h5> 
                        <p>
                            Verás que en cada plan/pack de patrocinio deberás decir qué gana el patrocinador al invertir en tu proyecto. 
                            Lo patrocinadores pagan al mes y quieren conocer el retorno de su inversión. Con las activaciones, 
                            tu objetivo es llevar más clientes a tu patrocinador para que siga invirtiendo. Si le prometes promocionar 
                            su marca 1 vez a la semana en el instagram, por ejemplo, hazlo y ganarás la confianza para fidelizarlo como tu patrocinador. 
                        </p>
                    </div>
                </div>
                <hr className="hr text-sponsify-red"/>
                <div className="row">
                    <div className="col-1">
                        <FontAwesomeIcon className="fs-1 text-sponsify-red" icon="fa-solid fa-layer-group" />
                    </div>
                    <div className="col-11">
                        <h5>El Patrocinio Sin Limites</h5> 
                        <p>
                            El patrocinador crea los cupones y promociones de su establecimiento. En su área en Sponsify aparecerá detalladamente 
                            la información del consumo efectuado a través de estos vales. Cuanto más vales vendidos, más retorno tendrá el patrocinador
                            en invertir en tu proyecto. Haz con que todos conozcan tus patrocinadores y prefieran comprar con él. 
                            Si tu patrocinador vende más con tu patrocinio, seguramente seguirá invirtiendo, incluso podrá decidirse por un plan más alto. 
                        </p>
                    </div>
                </div>
                <hr className="hr text-sponsify-red"/>
                <div className="row">
                    <div className="col-1">
                        <FontAwesomeIcon className="fs-1 text-sponsify-red" icon="fa-solid fa-chart-line" />
                    </div>
                    <div className="col-11">
                        <h5>El Retorno de la Inversión del Patrocinador</h5> 
                        <p>
                            El patrocinador crea los cupones y promociones de su establecimiento. En su área en Sponsify aparecerá detalladamente la información 
                            del consumo efectuado a través de estos vales. Cuanto más vales vendidos, más retorno tendrá el patrocinador en invertir en tu proyecto. 
                            Haz con que todos conozcan tus patrocinadores y prefieran comprar con él. Si tu patrocinador vende más con tu patrocinio, 
                            seguramente seguirá invirtiendo, incluso podrá decidirse por un plan más alto.  
                        </p>
                    </div>
                </div>
                <hr className="hr text-sponsify-red"/>
                <div className="row">
                    <div className="col-1">
                        <FontAwesomeIcon className="fs-1 text-sponsify-red" icon="fa-solid fa-magnifying-glass" />
                    </div>
                    <div className="col-11">
                        <h5>Busca tus Patrocinadores</h5> 
                        <p>
                            Busca patrocinadores que tengas una relación cercana, sea por proximidad física o emocional. 
                            Por ejemplo, si buscas patrocinio para el equipo de Hockey de tu hijo, elije las tiendas más 
                            cercanas o establecimientos y negocios de familiares del club. Cualquier persona podrá ayudarte a buscar patrocinio para tu proyecto. 
                            Define un % de la inversión conseguida para que vaya a la hucha del sorteo del final de la temporada. 
                            Cada persona que consiga dinero para tu proyecto recibirá un número para participar en el sorteo. 
                            Tú decides el valor que dará derecho a una participación. Además, decidirás qué y cuántos premios podrás ofrecer. 
                            Cuanto más atractivos sean el sorteo y la fiesta de fin de temporada, más personas estarán dispuestas a ayudarte a conseguir patrocinio.
                        </p>
                    </div>
                </div>
            </section>
        </div>
        </>
    );
};

export default HomePage;
