import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for approve voucher redemption
export const approveVoucherRedemption = createAsyncThunk('voucherRedemption/approveVoucherRedemption', async (voucherRedemptionId) => {
      const response = await fetch(`/api/vouchers/redemptions/approve`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ voucherRedemptionId })
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Network response was not ok:', errorText);
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
  });
  

const approveVoucherRedemptionSlice = createSlice({
  name: 'voucherRedemption',
  initialState: { voucherRedemption: '', status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(approveVoucherRedemption.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(approveVoucherRedemption.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.voucherRedemption = action.payload;
      })
      .addCase(approveVoucherRedemption.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default approveVoucherRedemptionSlice.reducer;