import React from 'react';

const SponsorsTermsOfService = () => {
    return (
        <div className="container my-5">
            <h1 className="mt-5">Términos y Condiciones de Uso</h1>
            <p>Última actualización: 26/10/24</p>
            <h3>1. Introducción</h3>
            <p>
                Sponsify, S.L., como Responsable del Tratamiento, le informa que, según lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril, 
                (RGPD) y en la L.O. 3/2018, de 5 de diciembre, de protección de datos y garantía de los derechos digitales (LOPDGDD), 
                trataremos su datos tal y como reflejamos en la presente Política de Privacidad.
                En esta Política de Privacidad describimos cómo recogemos sus datos personales y por qué los recogemos, qué hacemos con ellos, 
                con quién los compartimos, cómo los protegemos y sus opciones en cuanto al tratamiento de sus datos personales.
            </p>
            <h3>2. Definiciones</h3>
            <p>
                Sponsify.io: La plataforma que actúa como intermediaria entre patrocinadores y proyectos deportivos.
                Patrocinador: Persona o entidad que busca patrocinar proyectos deportivos.
                Proyecto Deportivo: Club, federación, deportista, evento, o cualquier iniciativa relacionada con el deporte que busca patrocinio.
            </p>
            <h3>3. Servicios Ofrecidos</h3>
            <p>
                Sponsify.io ofrece los siguientes servicios:
                Facilitar el matching entre patrocinadores y proyectos deportivos.
                Proporcionar informes de retorno de inversión a patrocinadores.
                Ofrecer diferentes planes de patrocinio, con o sin permanencia.
            </p>
            <h3>4. Comisiones</h3>
            <p>
                Sponsify.io retendrá una comisión del 10% sobre el valor total conseguido por los proyectos a través de la plataforma. 
                Las plataformas de pago (como Stripe, PayPal, MangoPay, entre otras) podrán cobrar sus propias comisiones en cada transacción.
            </p>
            <h3>5. Planes de Patrocinio</h3>
            <ul>
                <li>
                    <span className="fs-5 fw-bold">5.1. Planes Sin Permanencia</span><br/>
                    <ul>
                        <li>Duración: 30 días, renovables automáticamente.</li>
                        <li>Cancelación: Se puede cancelar en cualquier momento hasta un máximo de 10 días antes de la próxima renovación.</li>
                    </ul>   
                </li>
                <li>
                    <span className="fs-5 fw-bold">5.2. Planes con Permanencia</span><br/>
                    <ul>
                        <li>Duración: 12, 24, 36 o 48 meses, con contraprestaciones específicas.</li>
                        <li>Obligación: El patrocinador deberá mantener el pago mensual hasta el final del periodo de permanencia elegido..</li>
                    </ul>
                </li>
            </ul>
            <h3>6. Contraprestaciones</h3>
            <p>
                Cada proyecto debe ofrecer contraprestaciones claras al patrocinador, que deberán cumplirse en su totalidad durante la validez de la suscripción.
                En caso de incumplimiento, el proyecto tendrá un plazo de 30 días para subsanar la situación, 
                pudiendo enfrentarse a la devolución en doble del valor invertido y el bloqueo de su cuenta.
            </p>
            <h3>7. Mediación de Conflictos</h3>
            <p>
                Si surge un desacuerdo entre las partes, Sponsify.io actuará como mediador para resolver el conflicto.
            </p>
            <h3>8. Modificaciones</h3>
            <p>
                Sponsify.io se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento. 
                Las modificaciones serán efectivas al ser publicadas en la plataforma. Se recomienda revisar periódicamente los términos.
            </p>
            <h3>9. Legislación Aplicable</h3>
            <p>
                Estos Términos y Condiciones se rigen por la legislación vigente en España.
            </p>
            <h3>10. Aceptación de los Términos</h3>
            <p>
                Al utilizar Sponsify.io, aceptas estos Términos y Condiciones en su totalidad. Si no estás de acuerdo, no utilices nuestra plataforma.
            </p>
            
        </div>
    );
};

export default SponsorsTermsOfService;
