import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching vouchers by sponsor user id
export const fetchVouchersBySponsorUserId = createAsyncThunk('vouchersBySponsorUserId/fetchVouchersBySponsorUserId', async (userId) => {
  const response = await fetch(`/api/vouchers/user/${userId}`);
  const data = await response.json();
  return data;
});

const vouchersBySponsorUserIdSlice = createSlice({
  name: 'vouchersBySponsorUserId',
  initialState: { vouchersBySponsorUserId: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVouchersBySponsorUserId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVouchersBySponsorUserId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vouchersBySponsorUserId = action.payload;
      })
      .addCase(fetchVouchersBySponsorUserId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default vouchersBySponsorUserIdSlice.reducer;