import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updatePlan } from '../../redux/slices/updatePlanSlice';
import { fetchPlan } from '../../redux/slices/planSlice';
import { fetchSubscribedPlans } from '../../redux/slices/subscribedPlansSlice';
import { useTranslation } from 'react-i18next';
import BenefitFields from './BenefitFields';


const UpdatePlan = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { planId } = useParams();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user.id;

    const updatePlanStatus = useSelector((state) => state.updatePlan.status);
    const updatePlanError = useSelector((state) => state.updatePlan.error);
    const plan = useSelector((state) => state.plan.plan);
    const subscribedPlans = useSelector((state) => state.subscribedPlans.subscribedPlans || []);

    let subscribedPlanIds;
    let isSubscribed;
    if (subscribedPlans.length > 0) {
        subscribedPlanIds = subscribedPlans.map(plan => plan.plan_id);
        isSubscribed = subscribedPlanIds.includes(plan?.plan_id);
    };

    const planUserId = plan?.user_id;
    const isAuthUser = userId === planUserId;


    // check if the plan is cancelled
    // Get all subscribed plans for this plan_id
    // const relatedSubscribedPlans = subscribedPlans.filter(sub => sub.plan_id === plan?.plan_id);
    // Check if all cancel_at values are non-null
    // const isCancelled = relatedSubscribedPlans.length > 0 && relatedSubscribedPlans.every(sub => sub.cancel_at && new Date(sub.cancel_at) < new Date());

    useEffect(() => {
        dispatch(fetchPlan(planId));
        dispatch(fetchSubscribedPlans()); 
    }, [dispatch, planId]);

    const [formData, setFormData] = useState({
        name: '',
        price: '',
        benefits: []
    });

    // Pre-fill the form with plan data
    useEffect(() => {
        if (plan) {
            setFormData({
                name: plan.plan_name || '',
                price: plan.plan_prices[0].plan_price || '',
                benefits: plan.plan_benefits.map(benefit => ({
                    benefit_id: benefit.benefit_id,
                    description: benefit.description
                })) || []
            });
        }
    }, [plan]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const actionResult = await dispatch(updatePlan({ formData, planId }));
            if (updatePlan.fulfilled.match(actionResult)) {
                navigate(`/projects/${plan.project_id}`);
            }
        } catch (error) {
            console.error("Update plan error:", error);
        }
    }

    useEffect(() => {
        // Ensure the plan and subscribedPlans are loaded before checking conditions
        if (plan && subscribedPlans) {
            if (isSubscribed || !isAuthUser) {
                navigate('/');
            }
        }
    }, [navigate, isSubscribed, isAuthUser, plan, subscribedPlans]);

    useEffect(() => {
        if (updatePlanStatus === 'succeeded') {
            dispatch({ type: 'updatePlan/resetError' });
        } 
    }, [updatePlanStatus, dispatch]);

    return (
        <div className="container-fluid">
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <h2 className="text-center">{t('plans.editPlan')}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-11">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="name">{t('plans.name')}</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="name"
                                        name="name"
                                        autoComplete="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="price">{t('plans.pricePerMonth')}</label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        min="0.00"
                                        max="10000.00"
                                        step="0.01"
                                        id="price"
                                        name="price"
                                        autoComplete="price"
                                        value={formData.price}
                                        onChange={handleChange}
                                        required
                                    />       
                                </div>
                            </div>
                        </div>
                        <BenefitFields
                            initialBenefits={formData.benefits} 
                            onChange={(updatedBenefits) => setFormData({ ...formData, benefits: updatedBenefits })}
                        />
                        <div className="row">
                            <div className="col-11">
                                <div className="">
                                    <button type="submit" className="btn btn-lg btn-primary w-100" disabled={updatePlanStatus === 'loading'}>
                                        {updatePlanStatus === 'loading' ? 'Updating Plan...' : t('buttons.submit')}
                                    </button>
                                    {updatePlanError && <div className="text-danger mt-3">{updatePlanError}</div>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>  
            </div>    
        </div>
    );
};

export default UpdatePlan;