import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { requestPasswordReset } from '../../redux/slices/requestPasswordResetSlice';
import { useTranslation } from 'react-i18next';


const PasswordResetRequest = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { status } = useSelector((state) => state.requestPasswordReset);

    const [email, setEmail] = useState('');
    const [localError, setLocalError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await dispatch(requestPasswordReset(email));
            await navigate('/password-reset-request-success')
         
        } catch (error) {
            setLocalError(error.message);
            console.error('Error sending password reset link:', error);
        }
    
    };

    return (
        <div className="container-fluid">  
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <h2 className="text-center">{t('login.sendResetPasswordLink')}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                        <label className="form-label" htmlFor="email">{t('login.email')}</label>
                        <input
                            className="form-control"
                            type="email"
                            id="email"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        </div>
                        {localError && <div className="text-danger">{localError}</div>}
                        <button type="submit" className="btn btn-primary w-100" disabled={status === 'loading'}>
                            {status === 'loading' ? 'Sending...' : t('login.sendLink')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetRequest;