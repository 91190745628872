import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const resetPassword = createAsyncThunk('password/resetPassword', async ({ token, newPassword }) => {
  const response = await fetch(`/api/auth/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token, newPassword }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Password reset failed');
  }

  const data = await response.json();
  return data;
});

const resetPasswordSlice = createSlice({
  name: 'passwordReset',
  initialState: { status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default resetPasswordSlice.reducer;

