import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PreGranteeRegistration() {
    const { t } = useTranslation();

    return (
        <div className="row mt-5 pt-5 justify-content-center">
            <div className="col-lg-6">
                <h2 className="text-center">{t('registration.registerAsGrantee')}</h2>
                <p className="text-center">{t('registration.registerSearchSponsorship')}</p>
                <div className="">
                    <div className="d-grid gap-5 position-absolute top-50 start-50 translate-middle">
                        <Link to="/grantee-registration" state={{ granteeType: "individual" }} className="btn btn-lg btn-primary p-lg-4" type="button">
                            {t('registration.individual')}
                        </Link>
                        <Link to="/grantee-registration" state={{ granteeType: "organization" }} className="btn btn-lg btn-primary p-lg-4" type="button">
                            {t('registration.organization')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreGranteeRegistration;

