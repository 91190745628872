import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching plans by project Id
export const fetchPlansByProjectId = createAsyncThunk('plans/fetchPlansByProjectId', async (projectId) => {
  const response = await fetch(`/api/plans/project/${projectId}`);
  const data = await response.json();
  return data;
});

const plansByProjectIdSlice = createSlice({
  name: 'plans',
  initialState: { plans: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlansByProjectId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlansByProjectId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.plans = action.payload;
      })
      .addCase(fetchPlansByProjectId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default plansByProjectIdSlice.reducer;