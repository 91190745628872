import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createPlan } from '../../redux/slices/createPlanSlice';
import { fetchProject } from '../../redux/slices/projectSlice';
import { useTranslation } from 'react-i18next';


const CreatePlan = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { projectId } = useParams();

    const createPlanStatus = useSelector((state) => state.createPlan.status);
    const project = useSelector((state) => state.project.project);

    useEffect(() => {
        dispatch(fetchProject(projectId)); 
    }, [dispatch, projectId]);

    const [formData, setFormData] = useState({
        name: '',
        price: '',
        benefits: []
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const handleBenefitsChange = (e) => {
        const benefitsArray = e.target.value.split(';').map(benefit => benefit.trim());
        setFormData((prevData) => ({ ...prevData, benefits: benefitsArray }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Dispatch action to create voucher
        dispatch(createPlan({ formData, projectId }));
        setTimeout(() => {
            navigate(`/projects/${projectId}`);
        }, 1000); // Wait for 1 second before navigating to ensure create plan completes
    }

    return (
        <div className="container-fluid">
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <h2 className="text-center">{t('plans.createPlan')} {t('general.for')} {project.project_name}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">{t('plans.name')}</label>
                            <input
                                className="form-control"
                                type="text"
                                id="name"
                                name="name"
                                autoComplete="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="price">{t('plans.pricePerMonth')}</label>
                            <input
                                className="form-control"
                                type="number"
                                min="0.00"
                                max="10000.00"
                                step="0.01"
                                id="price"
                                name="price"
                                autoComplete="price"
                                value={formData.price}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="benefits">{t('plans.benefits')}</label>
                            <textarea
                                className="form-control"
                                id="benefits"
                                name="benefits"
                                autoComplete="benefits"
                                value={formData.benefits.join('; ')}
                                onChange={handleBenefitsChange}
                                placeholder={t('plans.benefitsPlaceholder')}
                                rows="3"
                                required
                            />
                        </div>
                        <div className="">
                            <button type="submit" className="btn btn-lg btn-primary w-100" disabled={createPlanStatus === 'loading'}>
                                {createPlanStatus === 'loading' ? 'Creating Project...' : t('buttons.submit')}
                            </button>
                        </div>
                    </form>   
                </div>
            </div>    
        </div>
    );
};

export default CreatePlan;