import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching projects
export const fetchProjects = createAsyncThunk('projects/fetchProjects', async () => {
  const response = await fetch('/api/projects');
  const data = await response.json();
  return data;
});

const projectsSlice = createSlice({
  name: 'projects',
  initialState: { projects: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.projects = action.payload;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default projectsSlice.reducer;