import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for updating grantee
export const updateGrantee = createAsyncThunk('grantee/updateGrantee', async ({ submissionData, userId }, { rejectWithValue }) => {
      let response;
      try {
        response = await fetch(`/api/grantees/user/${userId}`, {
          method: 'PUT',
          body: submissionData
      });
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Network response was not ok:', errorData);
        // Reject with error data
        return rejectWithValue(errorData.error || 'Grantee updating failed');
      }
      const data = await response.json();
      return data;

      } catch (error) {
        console.error('Error in createProject thunk', error);
        return rejectWithValue(error.message || 'An error occurred during the API call');
      }
  });
  

const updateGranteeSlice = createSlice({
  name: 'grantee',
  initialState: { grantee: null, status: 'idle', error: null },
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateGrantee.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateGrantee.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.grantee = action.payload;
      })
      .addCase(updateGrantee.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

export const { resetStatus } = updateGranteeSlice.actions;
export default updateGranteeSlice.reducer;