import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for creating voucher
export const createVoucher = createAsyncThunk('voucher/createVoucher', async (formData) => {

  let response;
  try {
    response = await fetch(`/api/vouchers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Voucher creation failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in createPlan thunk', error);
    throw new Error(error.message || 'An error occurred during the API call');
  }
});

const createVoucherSlice = createSlice({
  name: 'voucher',
  initialState: { status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createVoucher.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createVoucher.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.voucher = action.payload;
      })
      .addCase(createVoucher.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default createVoucherSlice.reducer;
