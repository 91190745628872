import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for sponsor registration
export const registerSponsor = createAsyncThunk('sponsorRegistration/registerSponsor', async (userData) => {
  const response = await fetch('/api/sponsors', {
    method: 'POST',
    body: userData,
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error);
  }
  return data
});

const sponsorRegistrationSlice = createSlice({
  name: 'sponsorRegistration',
  initialState: { status: 'idle', error: null },
  reducers: {
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerSponsor.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerSponsor.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sponsorRegistration = action.payload;
      })
      .addCase(registerSponsor.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetError } = sponsorRegistrationSlice.actions;
export default sponsorRegistrationSlice.reducer;