import React from 'react';
import { useDispatch } from 'react-redux';
import { generateQrCode } from '../../redux/slices/generateQrCodeSlice';

const QrCodeGenerator = () => {
  const dispatch = useDispatch();

  const handleGenerateQrCode = async () => {
    dispatch(generateQrCode());
  };

  return (
    <div>
      <button onClick={handleGenerateQrCode}>Generate QR Code</button>
    </div>
  );
};

export default QrCodeGenerator;
