import React from 'react';
import { useTranslation } from 'react-i18next';


const PaymentSuccess = ({ sessionId, plan, project }) => {
    const { t } = useTranslation();
    
    return (
        <div className="container mt-5 pt-5">	
            <div className="text-center">
                <p>{t('plans.SubscriptionToThe')} <strong>{project}</strong> <strong>{plan}</strong> {t('plans.successful!')}</p>
                <form action="/api/payment/create-portal-session" method="POST">
                    <input
                    type="hidden"
                    id="session-id"
                    name="session_id"
                    value={sessionId}
                    />
                    <button className="btn btn-primary" id="checkout-and-portal-button" type="submit">
                        {t('buttons.manageYourBillingInformation')}
                    </button>
            </form>
            </div>
        </div>
    );
};

export default PaymentSuccess;