import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { registerGrantee } from '../../redux/slices/granteeRegistrationSlice';
import { useTranslation } from 'react-i18next';


const GranteeRegistration = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const registrationStatus = useSelector((state) => state.granteeRegistration.status);
    const registrationError = useSelector((state) => state.granteeRegistration.error);

    const { granteeType } = location.state || {};

    const [selectedFile, setSelectedFile] = useState(null);
    const [formData, setFormData] = useState({
        granteeType: granteeType || '',
        organizationName: '',
        organizationDescription: '',
        url: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        logo: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const handleFileChange = (e) => {
        const logo = e.target.files[0];
        setSelectedFile(logo ? logo.name : t('registration.noLogoChosen'));
        setFormData((prevData) => ({ ...prevData, logo }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        const submissionData = new FormData();
        for (const key in formData) {
            submissionData.append(key, formData[key]);
        }

        try {
            const actionResult = await dispatch(registerGrantee(submissionData));
            // Check if the registration action succeeded
            if (registerGrantee.fulfilled.match(actionResult)) {
                // Registration successful, navigate to success page
                navigate('/registration-success');
            }
        } catch (error) {
            // Handle registration error
            console.error("Registration error:", error);
        }
    }

    useEffect(() => {
        // Check if registration status is 'succeeded' before clearing the form
        if (registrationStatus === 'succeeded') {
            // Clear form data and reset error message upon successful registration
            setFormData({
                granteeType: '',
                organizationName: '',
                organizationDescription: '',
                url: '',
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: '',
                logo: null,
            });

            // Reset error message
            dispatch({ type: 'registration/resetError' });
        } 

    }, [registrationStatus, dispatch]);

    return (
        <div className="container-fluid">
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <h2 className="text-center">{t('registration.registerAsGrantee')}</h2>
                    <p className="text-center">{t('registration.registerSearchSponsorship')}</p>
                    <form onSubmit={handleSubmit}>
                        {granteeType === 'organization' && (
                            <>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="organizationName">{t('registration.organizationName')}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="organizationName"
                                    name="organizationName"
                                    autoComplete="organization"
                                    value={formData.organizationName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                            <label className="form-label" htmlFor="organizationDescription">{t('registration.organizationDescription')}</label>
                                <textarea
                                    className="form-control"
                                    type="text"
                                    id="organizationDescription"
                                    name="organizationDescription"
                                    autoComplete="description"
                                    value={formData.organizationDescription}
                                    onChange={handleChange}
                                    required
                                    rows="4"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="url">{t('registration.website')}</label>
                                <input
                                    className="form-control"
                                    type="url"
                                    id="url"
                                    name="url"
                                    autoComplete="url"
                                    value={formData.url}
                                    onChange={handleChange}
                                    placeholder="https://example.com"
                                    pattern="https?://.+"
                                    title="Include http:// or https://"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="logo">{t('registration.logo')}</label>
                                <div className="custom-file-input">
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="logo"
                                        name="logo"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                    />
                                    <label className="form-control" htmlFor="logo">
                                        {selectedFile || t('registration.chooseLogo')}
                                    </label>
                                </div>
                            </div>
                            </>
                        )}
                        <div className="mb-3">
                            <label className="form-label" htmlFor="firstName">{t('registration.firstName')}</label>
                            <input
                                className="form-control"
                                type="text"
                                id="firstName"
                                name="firstName"
                                autoComplete="given-name"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="lastName">{t('registration.lastName')}</label>
                            <input
                                className="form-control"
                                type="text"
                                id="lastName"
                                name="lastName"
                                autoComplete="family-name"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="email">{t('registration.email')}</label>
                            <input
                                className="form-control"
                                type="email"
                                id="email"
                                name="email"
                                autoComplete="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        {granteeType === 'individual' && (
                            <div className="mb-3">
                                <label className="form-label" htmlFor="logo">{t('registration.profilePicture')}</label>
                                <div className="custom-file-input">
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="logo"
                                        name="logo"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                    />
                                    <label className="form-control" htmlFor="logo">
                                        {selectedFile || t('registration.chooseProfilePicture')}
                                    </label>
                                </div>
                            </div>
                        )}
                        <div className="mb-3">
                            <label className="form-label" htmlFor="password">{t('registration.password')}</label>
                            <input
                                className="form-control"
                                type="password"
                                id="password"
                                name="password"
                                autoComplete="current-password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="confirmPassword">{t('registration.confirmPassword')}</label>
                            <input
                                className="form-control"
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                autoComplete="current-password"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="tos"
                                    name="tos"
                                    required
                                />
                                <label className="form-check-label" htmlFor="tos">
                                    {t('registration.registrationAcknowledge')} 
                                    {' '}
                                    <Link to="/terms-of-service"> {t('registration.tos')}</Link>, 
                                    {' '}
                                    <Link to="/privacy-policy">{t('registration.privacyPolicy')}</Link> 
                                    {' '}
                                    {t('general.and')} 
                                    {' '}
                                    <Link to="/cookies-policy">{t('registration.cookiesPolicy')}.</Link>
                                </label>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-lg btn-primary w-100" disabled={registrationStatus === 'loading'}>
                            {registrationStatus === 'loading' ? 'Registering...' : t('registration.register')}
                        </button>
                        {registrationError && <div className="text-danger mt-3">{registrationError}</div>}
                    </form>   
                </div>
            </div>    
        </div>
    );
};

export default GranteeRegistration;