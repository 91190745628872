import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for creating Stripe billing portal
export const createStripeBillingPortal = createAsyncThunk('stripeBillingPortal/createStripeBillingPortal', async (userId) => {
  const response = await fetch('/api/orders/billing-portal', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error);
  }
  return data
});

const createStripeBillingPortalSlice = createSlice({
  name: 'stripeBillingPortal',
  initialState: { status: 'idle', error: null },
  reducers: {
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStripeBillingPortal.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createStripeBillingPortal.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stripeBillingPortal = action.payload;
      })
      .addCase(createStripeBillingPortal.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default createStripeBillingPortalSlice.reducer;