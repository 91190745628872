import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { fetchQrCodes } from '../../redux/slices/qrCodesSlice';


function QrCodes() {
    const dispatch = useDispatch();

    const qrCodes = useSelector((state) => state.qrCodes.qrCodes);

    useEffect(() => {
        dispatch(fetchQrCodes()); 
    }, [dispatch]);

    return (
        <div className="container">
            <h1 className="page-title">QR Codes</h1>
            <ul className="items no-list">
                {qrCodes.map((qrCode) => (
                    <li key={qrCode.id}>
                        <div className="">
                            <img src={qrCode.qrcode} alt={qrCode.sponsor_id}/>
                            <p>{qrCode.sponsor_name}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default QrCodes;