import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { fetchPlansByProjectId } from '../../redux/slices/plansByProjectIdSlice';
import { fetchSubscribedPlans } from '../../redux/slices/subscribedPlansSlice';
import { createCheckoutSession } from '../../redux/slices/createCheckoutSessionSlice';
import { archivePlan } from '../../redux/slices/archivePlanSlice';
import { publishPlan } from '../../redux/slices/publishPlanSlice';
import { removePlan } from '../../redux/slices/removePlanSlice';

import Modal from 'react-bootstrap/Modal';
import './Plans.css';

function Plans({ projectId, connectedAccountId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const userId = userData?.user?.id;
    const sponsorUser = userData?.user?.user_type === 'sponsor';

    const [messageByPlanId, setMessageByPlanId] = useState({});
    const [showRemoveArchivePlanConfirmation, setShowRemoveArchivePlanConfirmation] = useState(false);
    const [showPublishPlanConfirmation, setShowPublishPlanConfirmation] = useState(false);
    const [planIdToRemoveArchive, setPlanIdToRemoveArchive] = useState('');
    const [planToRemoveArchive, setPlanToRemoveArchive] = useState({});
    const [planToPublish, setPlanToPublish] = useState({});
    const [isPublishedPlan, setIsPublishedPlan] = useState(false);

    const plansByProjectId = useSelector((state) => state.plansByProjectId.plans);
    const subscribedPlans = useSelector((state) => state.subscribedPlans.subscribedPlans);
    
    let isAuthUser = false;
    if (Array.isArray(plansByProjectId)) {
        isAuthUser = plansByProjectId?.some(plan => plan.user_id === userId);
    };

    // Check if the plans are subscribed
    let plansByProjectIdWithSubscriptionStatus
    if (Array.isArray(plansByProjectId)) {
        // Ensure subscribedPlans is an array before mapping
        const subscribedPlanIds = Array.isArray(subscribedPlans)
            ? subscribedPlans.map(plan => plan.plan_id)
            : [];
        plansByProjectIdWithSubscriptionStatus = plansByProjectId?.map(plan => ({
            ...plan,
            is_subscribed: subscribedPlanIds.includes(plan.plan_id)
        }));
    };

    // Add is_cancelled to plansByProjectIdWithSubscriptionStatus
    // Check if all cancel_at are non-null for a specific plan_id
    const plansByProjectIdWithSubscriptionStatusAndWithIsCancelled = plansByProjectIdWithSubscriptionStatus?.map(plan => {
        // Ensure subscribedPlans is an array and get all subscribed plans for this plan_id
        const relatedSubscribedPlans = Array.isArray(subscribedPlans) 
            ? subscribedPlans?.filter(sub => sub.plan_id === plan.plan_id) 
            : [];
        // Check if all cancel_at values are non-null
        const isCancelled = relatedSubscribedPlans.length > 0 && relatedSubscribedPlans.every(sub => sub.cancel_at && new Date(sub.cancel_at) < new Date());
        // Add is_cancelled to the plan
        return {
            ...plan,
            is_cancelled: isCancelled
        };
    });

    useEffect(() => {
        dispatch(fetchPlansByProjectId(projectId));
        dispatch(fetchSubscribedPlans());
    }, [dispatch, projectId]);

    const handleRemoveArchivePlan = async (planId) => {
        setPlanIdToRemoveArchive(planId); // no need to await
        const plan = plansByProjectIdWithSubscriptionStatus.find(({ plan_id }) => plan_id === planId); 
        setIsPublishedPlan(plan.plan_published);
        setPlanToRemoveArchive(plan);
        setShowRemoveArchivePlanConfirmation(true);
    };

    const cancelRemoveArchivePlan = () => {
        setShowRemoveArchivePlanConfirmation(false);
    };

    const confirmRemoveArchivePlan = async () => {
        if (isPublishedPlan) {
            await dispatch(archivePlan(planIdToRemoveArchive));
        } else {
            await dispatch(removePlan(planIdToRemoveArchive));
        }
        
        await dispatch(fetchPlansByProjectId(projectId));
        await setShowRemoveArchivePlanConfirmation(false);
    };

    const handlePublishPlan = async (planId) => {
        const plan = plansByProjectId.find(({ plan_id }) => plan_id === planId);
        const formattedPlan = {
            planId: plan.plan_id,
            projectId: plan.project_id,
            planName: plan.plan_name,
            planPrice: plan.plan_prices[0].plan_price,
            projectName: plan.project_name
        }
        setPlanToPublish(formattedPlan);
        setShowPublishPlanConfirmation(true);
    };

    const cancelPublishPlan = () => {
        setShowPublishPlanConfirmation(false);
    };

    const confirmPublishPlan = async () => {
        await dispatch(publishPlan(planToPublish));
        await dispatch(fetchPlansByProjectId(projectId));
        await setShowPublishPlanConfirmation(false);
    };

    const isPlanSubscribedByAuthSponsor = (planId) => {
        if (Array.isArray(subscribedPlans)) {
            return subscribedPlans?.some(plan => plan.user_id === userId && plan.plan_id === planId);
        }
    };

    const isPlanCancelledByAuthSponsor = () => {
        if (Array.isArray(subscribedPlans)) {
            return subscribedPlans.some(plan => plan.cancel_at && new Date(plan.cancel_at) < new Date());
        }
    };

    const handleSponsorNow = async (stripeLookupKey, planId) => {
        if ((userId && sponsorUser && !isPlanSubscribedByAuthSponsor(planId)) || (userId && sponsorUser && isPlanCancelledByAuthSponsor(planId))) {
            try {
                const response = await dispatch(createCheckoutSession({ userId, connectedAccountId, stripeLookupKey })).unwrap();
                window.location.href = response.url; // Redirect to the Stripe checkout page
              } catch (error) {
                console.error('Error creating checkout session:', error);
                // Handle error appropriately
              }
        } else if (userId && sponsorUser && isPlanSubscribedByAuthSponsor(planId)){
            setMessageByPlanId(prevState => ({
                ...prevState,
                [planId]: t('plans.planAlreadySubscribed')
            }));
        } else if(!userId) {
            navigate('/login');
        } else {
            setMessageByPlanId(prevState => ({
                ...prevState,
                [planId]: t('plans.warning')
            }));
        }
    };

    return (
        <>
            {plansByProjectId.length > 0 && 
            !plansByProjectId.filter(plan => plan.plan_published || isAuthUser).every(plan => plan.plan_archived) ? (
            <>
            <div className="row">
                {plansByProjectIdWithSubscriptionStatusAndWithIsCancelled.filter(plan => !plan.plan_archived).map((plan, index) => (
                    <div className="col-lg-4 mb-4" key={index}>
                        <div className="card">
                            <div className="card-body d-flex flex-column justify-content-center">
                                <h4 className="card-title text-center fs-3 fw-bold">{plan.plan_name}</h4>
                                <h2 className="card-title text-center pricing-card-title fs-5 fw-bold">
                                    {plan.plan_price === 0.00 ? 'Free' : `€${plan.plan_prices[0].plan_price.toFixed(2)}`} 
                                    <span className="fs-6 fw-normal">/{t('general.mo')}</span><br/>
                                    <span className="fw-light fs-6">(+ IVA)</span>
                                </h2>
                                <ul className="list-group list-group-flush mt-3 mb-4">
                                    {plan.plan_benefits.map((benefit) => (
                                        <li className="list-group-item" key={benefit.benefit_id}>{benefit.description}</li>
                                    ))}
                                </ul>
                                {isAuthUser ? (
                                    <>
                                    <Link  
                                        to={`/update-plan/${plan.plan_id}`}
                                        type="button" 
                                        className={`${plan.is_subscribed ? 'disabled btn-outline-primary' : 'btn-primary' } btn text-capitalize mb-2`}
                                    >
                                        {t('buttons.edit')}
                                    </Link> 
                                    <button 
                                        onClick={() => handlePublishPlan(plan.plan_id)}
                                        className={`${plan.plan_published ? 'disabled btn-outline-primary' : 'btn-primary' } btn text-capitalize mb-2`}
                                        type="button" 
                                    >
                                        {plan.plan_published ? t('buttons.published') : t('buttons.publish') }
                                    </button> 
                                    <button 
                                        onClick={() => handleRemoveArchivePlan(plan.plan_id)}
                                        className={`${plan.plan_archived ? 'disabled btn-outline-primary' : 'btn-primary' } btn text-capitalize`}
                                        type="button" 
                                    >
                                        {plan.plan_published ? t('buttons.archive') : t('buttons.remove') }
                                    </button>
                                    </>                              
                                ) : (
                                    <button  
                                        onClick={() => handleSponsorNow(plan.plan_prices[0].stripe_lookup_key, plan.plan_id)} 
                                        type="button" 
                                        className="btn btn-lg btn-block btn-primary text-uppercase"
                                    >
                                        {t('buttons.sponsorNow')}
                                    </button>
                                )}
                            </div>
                        </div>
                        {messageByPlanId[plan.plan_id] && (
                            <div className="alert alert-warning mt-2">
                                {messageByPlanId[plan.plan_id]}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <Modal show={showRemoveArchivePlanConfirmation} >
                <Modal.Header closeButton onClick={cancelRemoveArchivePlan}>
                    <Modal.Title className="text-capitalize">  
                        {planToRemoveArchive && !isPublishedPlan ? (
                            t('plans.confirmPlanRemoval')
                        ) : (
                            t('plans.confirmPlanArchiving')
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {planToRemoveArchive && !isPublishedPlan ? (
                        <p>{t('plans.removePlanModalMessage')} <strong>{planToRemoveArchive.plan_name}</strong>?</p>
                    ) : (
                        <p>{t('plans.archivePlanModalMessage')} <strong>{planToRemoveArchive.plan_name}</strong>?</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={cancelRemoveArchivePlan}>{t('buttons.cancel')}</button>
                    <button type="button" className="btn btn-primary" onClick={confirmRemoveArchivePlan}>{t('buttons.confirm')}</button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPublishPlanConfirmation} >
                <Modal.Header closeButton onClick={cancelPublishPlan}>
                    <Modal.Title className="text-capitalize">{t('plans.confirmPlanPublication')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {planToPublish ? (
                        <p>{t('plans.publishPlanModalMessage')} <strong>{planToPublish.planName}</strong>?</p>
                    ) : (
                        <p>Loading plan details...</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={cancelPublishPlan}>{t('buttons.cancel')}</button>
                    <button type="button" className="btn btn-primary" onClick={confirmPublishPlan}>{t('buttons.confirm')}</button>
                </Modal.Footer>
            </Modal>
            </>
        ) : (
            <div className="container">{t('plans.sponsorshipPlanDisplay')}</div>
        )}
        </>
    );
};

export default Plans;