import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EmailVerificationSuccessPage = () => {
    const { t } = useTranslation();

    return (
        <div className="container mt-5 pt-5">
            <div className="text-center">
                <h1>{t('registration.EmailVerificationSuccessful')}</h1>
                <p>
                    {t('registration.emailSuccessfullyVerified')}<br/>
                    {t('registration.youCanlogin')}
                </p>
                <Link to="/login" className="btn btn-primary">{t('login.login')}</Link>
            </div>
        </div>
    );
};

export default EmailVerificationSuccessPage;
