import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProject } from '../../redux/slices/projectSlice';
import { fetchSponsorsByProjectId } from '../../redux/slices/sponsorsByProjectIdSlice';
import { useTranslation } from 'react-i18next';
import Plans from '../plans/Plans';
import PlansTable from '../plans/PlansTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Projects.css';

import { getMonthYear } from '../../utils/getMonthYear';

function ProjectPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { projectId } = useParams();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user?.id;

    const project = useSelector((state) => state.project.project);
    const connectedAccountId = project ? project.connected_account_id : null;
    const sponsors = useSelector((state) => state.sponsorsByProjectId.sponsors) || [];

    const isAuthUser = project?.user_id === userId;

    useEffect(() => {
        dispatch(fetchSponsorsByProjectId(projectId));
        dispatch(fetchProject(projectId));
    }, [dispatch, projectId]);

    const activeSponsors = Array.isArray(sponsors)
        ? sponsors
            .flatMap(activeSponsor => activeSponsor.sponsors || [])
            .filter(sponsor => !sponsor.subscription_cancel_at || new Date(sponsor.subscription_cancel_at) > new Date())
        : [];

    const uniqueActiveSponsors = Array.from(new Set(activeSponsors.map(sponsor => sponsor.sponsor_id)))
        .map(id => activeSponsors.find(sponsor => sponsor.sponsor_id === id));

    return (
        <>
        {/* Hero */}
        {project.project_images && project.project_images.length > 0 ? (
            <div
                className="bg-primary px-4 py-5 margin-top-58 text-center position-relative"
                style={{
                    backgroundImage: `url(https://storage.googleapis.com/sponsify/projects/${project.project_images[1] || project.project_images[0]})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className="overlay hero-overlay z-1"/>
                <div className="py-3 position-relative z-2">
                    <h1 className="display-5 fw-bold text-white text-uppercase">{project.project_name}</h1>
                </div>
            </div>
        ):(
            <div className="bg-primary px-4 py-5 margin-top-58 text-center">
                <div className="py-3">
                    <h1 className="display-5 fw-bold text-white">{project.project_name}</h1>
                </div>
            </div>
        )}

        {/* Sub-navbar */}
        <nav className="navbar navbar-expand position-sticky mb-3 py-3 bg-white sub-navbar border">
            <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">

                {/* Grantee */}
                <div className="d-flex align-items-center">
                    <div className="me-1">
                        {project.grantee_logo ? (
                            <img 
                                className="rounded-circle circular-image"
                                src={`https://storage.googleapis.com/sponsify/grantees-logo/${project.grantee_logo}`} 
                                alt={project.project_name}
                                height="50px"
                                onError={(e) => {
                                    e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                }} 
                            />
                        ):(
                            <div className="circular-container bg-primary text-white fs-3">
                                {project.grantee_name?.charAt(0) || ''}
                            </div>
                        )}  
                    </div>
                    {project.grantee_name}
                </div>

                {/* Navigation */}
                <div className="navbar-nav">
                    <a className="nav-link text-capitalize active" aria-current="page" href="#about">{t('general.about')}</a>
                    <a className="nav-link text-capitalize" href="#gallery">{t('projects.gallery')}</a>
                    <a className="nav-link text-capitalize" href="#sponsors">{t('sponsors.sponsors')}</a>
                    <a className="nav-link text-capitalize" href="#plans">{t('plans.plans')}</a>
                </div>
          
                
                {/* Social media */}
                <div className="">
                    {project.grantee_facebook && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_facebook} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-brands fa-facebook" /></a>
                    )}
                    {project.grantee_instagram && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_instagram} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-brands fa-instagram" /></a>
                    )}
                    {project.grantee_tiktok && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_tiktok} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-brands fa-tiktok" /></a>
                    )}
                    {project.grantee_x && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_x} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-brands fa-x-twitter" /></a>                       
                    )}
                    {project.grantee_linkedin && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_linkedin} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a>
                    )}
                    {project.grantee_pinterest && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_pinterest} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-brands fa-pinterest" /></a>
                    )}
                    {project.grantee_url && (
                        <a className="fs-4 link-primary ms-3" href={project.grantee_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="fa-solid fa-globe" /></a>
                    )}
                </div>
            </div>
        </nav>

        {/* Main body */}
        <div className="container">
            {/* About */}
            <section className="mt-5" id="about">
                <h1 className="display-6 text-capitalize">{t('general.about')}</h1>
                <hr className="custom-divider mb-4" />
                <div className="d-flex align-items-center mb-3">
                    {project.event_dates && project.event_dates.length > 0 && (
                        <figure className="text-center text-primary border-5 border-top border-primary rounded-1">
                            <div className="px-1 fs-3 fw-bold text-uppercase border">{getMonthYear(project?.event_dates[0].start_date, 'es')[0]}</div>
                            <div className="bg-primary text-white rounded-bottom-1">{getMonthYear(project?.event_dates[0].start_date, 'es')[1]}</div>
                        </figure>
                    )}
                    <div className="text-primary fs-4 ms-auto">  
                        {project.is_official && <FontAwesomeIcon className="ms-3" title={t('sports.official')} icon="fa-solid fa-medal" />}
                        {project.is_seasonal && <FontAwesomeIcon className="ms-3" title={t('sports.seasonal')} icon="fa-solid fa-calendar" />}
                    </div>
                </div>
                <p className="lead">{project.project_description}</p>  
            </section> 

            {/* Gallery */}
            <section className="mt-5" id="gallery">
                <div id="carouselExampleIndicators" className="carousel slide">
                    <div className="carousel-indicators pb-3">
                        {project.project_images && project.project_images.map((_, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to={index}
                                className={index === 0 ? 'active' : ''}
                                aria-current={index === 0 ? 'true' : 'false'}
                                aria-label={`Slide ${index + 1}`}
                            ></button>
                        ))}
                    </div>
                    <div className="carousel-inner carousel-img d-lg-flex align-items-center">
                        {project.project_images && project.project_images.map((image, index) => (
                            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                <img
                                    src={`https://storage.googleapis.com/sponsify/projects/${image}`}
                                    className="d-lg-flex text-center mx-auto"
                                    alt={project.project_name}
                                    onError={(e) => {
                                        e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </section> 

            {/* Our sponsors */}
            <section className="mt-5" id="sponsors">
                <h1 className="display-6">{t('sponsors.sponsors')}</h1>
                <hr className="custom-divider mb-5" />
                {uniqueActiveSponsors.length > 0 ? (
                    <div className="row">
                        {uniqueActiveSponsors.map((sponsor) => (
                            <div key={sponsor.order_id} className="col-lg-2">
                                <Link to={`/sponsors/${sponsor.sponsor_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <div className="text-center">
                                        <img 
                                            src={`https://storage.googleapis.com/sponsify/sponsors-logo/${sponsor.sponsor_logo}`} 
                                            className="img-thumbnail" 
                                            alt={sponsor.name}
                                            onError={(e) => {
                                                e.target.src = '/images/companies-logo/missing-image.svg';
                                            }}
                                        />
                                        <h4 className="mt-3">{sponsor.sponsor_name}</h4>  
                                    </div> 
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>{t('sponsors.becomeOurFirstSponsor')}</p>
                )}
            </section>

            {/* Plans */}
            <section className="mt-5" id="plans">
                <h1 className="display-6">
                    {t('sponsors.becomeOurSponsor')}
                    {isAuthUser &&
                        <span className="ms-3">
                            <Link to={`/plan-templates/project/${projectId}`} className="btn btn-primary" type="button">{t('plans.addPlan')}</Link>
                        </span>
                    }
                </h1>
                <hr className="custom-divider mb-5" />
                {/* Show PlansTable on large screens (lg and up) */}
                <div className="d-none d-lg-block">
                    <PlansTable projectId={projectId} connectedAccountId={connectedAccountId} />
                </div>
                {/* Show Plans on small and medium screens (less than lg) */}
                <div className="d-block d-lg-none">
                    <Plans projectId={projectId} connectedAccountId={connectedAccountId} />
                </div>
            </section>
        </div>
        </>
    );
}

export default ProjectPage;
