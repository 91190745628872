import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/slices/authenticationSlice';

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const handleLogout = async () => {
            try {
                await dispatch(logout());
                navigate('/');
            } catch (error) {
                console.error(error);
            }
        };

        handleLogout();
    }, [dispatch, navigate]);

    return <div>Logging out...</div>;
}

export default Logout;