import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching ItemForSale
export const requestPasswordReset = createAsyncThunk('passwordReset/requestPasswordReset', async (email) => {
    const response = await fetch(`/api/auth/password-reset-link`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Send password reset link failed');
    }

    const data = await response.json();
    return data;

  }
);

const requestPasswordResetSlice = createSlice({
  name: 'passwordReset',
  initialState: { status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestPasswordReset.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(requestPasswordReset.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.passwordReset = action.payload;
      })
      .addCase(requestPasswordReset.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default requestPasswordResetSlice.reducer;

