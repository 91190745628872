// authenticationSlice.js
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    isLoggedIn: false,
    loading: false,
    error: null,
};

// Define the login success action
export const loginSuccess = createAction('auth/loginSuccess');

export const login = createAsyncThunk('auth/login', async (credentials) => {
    try {
        const response = await fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });
  
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Login failed');
        }
  
        const user = await response.json();
        localStorage.setItem('userData', JSON.stringify(user));
        localStorage.setItem('isLoggedIn', 'true');
    
        return user;
    } catch (error) {
      throw error;
    }
});

export const logout = createAsyncThunk('auth/logout', async () => {
    try {
        await fetch('/api/auth/logout');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('userData');
        return null;
    } catch (error) {
        throw error;
    }
});

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isLoggedIn = true;
                state.loading = false;
                state.error = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(logout.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null;
                state.isLoggedIn = false;
                state.loading = false;
                state.error = null;
            })
            .addCase(logout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(loginSuccess, (state, action) => {
                state.user = action.payload;
                state.isLoggedIn = true;
                state.loading = false;
                state.error = null;
            });
    },
});

export default authenticationSlice.reducer;