import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching createCheckoutSession
export const createCheckoutSession = createAsyncThunk('checkoutSession/createCheckoutSession', 
    async ({ userId, connectedAccountId, stripeLookupKey }) => {
    let response;
    try {
		response = await fetch(`/api/payment/create-checkout-session/user/${userId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
        body: JSON.stringify({ connectedAccountId, stripeLookupKey }),
    });

	if (!response.ok) {
		const errorData = await response.json();
		throw new Error(errorData.message || 'Checkout session creation failed');
	}

	const data = await response.json();
	return data;
    } catch (error) {
		console.error('Error in createCheckoutSession thunk', error);
		throw new Error(error.message || 'An error occurred during the API call');
    }
});

const createCheckoutSessionSlice = createSlice({
  name: 'checkoutSession',
  initialState: { checkoutSession: null, status: 'idle', loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCheckoutSession.pending, (state) => {
        state.status = 'loading';
        state.loading = true; // Set loading to true when the request is pending
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.checkoutSession = action.payload;
        state.loading = false; // Set loading back to false when the request is fulfilled
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false; // Set loading back to false when the request is rejected
      });
  },
});

export default createCheckoutSessionSlice.reducer;