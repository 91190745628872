import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetch voucher redemptions by voucher id
export const fetchVoucherRedemptionsByVoucherId = createAsyncThunk('voucherRedemptionsByVoucherId/fetchVoucherRedemptionsByVoucherId', async (voucherId) => {
  const response = await fetch(`/api/vouchers/redemptions/voucher/${voucherId}`);
  const data = await response.json();
  return data;
});

const voucherRedemptionsByVoucherIdSlice = createSlice({
  name: 'voucherRedemptionsByVoucherId',
  initialState: { voucherRedemptionsByVoucherId: '', status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVoucherRedemptionsByVoucherId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVoucherRedemptionsByVoucherId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.voucherRedemptionsByVoucherId = action.payload;
      })
      .addCase(fetchVoucherRedemptionsByVoucherId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default voucherRedemptionsByVoucherIdSlice.reducer;