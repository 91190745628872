import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching subscribed plans
export const fetchSubscribedPlans = createAsyncThunk('subscribedPlans/fetchSubscribedPlans', async () => {
  const response = await fetch('/api/plans/subscribed');
  const data = await response.json();
  return data;
});

const subscribedPlansSlice = createSlice({
  name: 'subscribedPlans',
  initialState: { subscribedPlans: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscribedPlans.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSubscribedPlans.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.subscribedPlans = action.payload;
      })
      .addCase(fetchSubscribedPlans.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default subscribedPlansSlice.reducer;